import apiPath from '../../../config/api';

const PayablePath = {
    title: "Pembayaran Hutang Supplier",
    basePath: "/akunting/hutang",
    newPath: "/akunting/hutang/baru",
    detailPath: "/akunting/hutang/opsi/:id",
    baseApi: apiPath.accountPayable,
    supplierFind: apiPath.accountPayableSupplier,
    paymentFind: apiPath.masterPaymentFind
}

export default PayablePath;
