import apiPath from '../../../config/api';

const StockSalesPath = {
    title: "Toko Material - Persediaan dan Penjualan",
    basePath: "/material/stok-sales",
    base: apiPath.retailStockSales,
    stockSalesSum: apiPath.retailStockSalesSum,
    stockSalesExcel: apiPath.retailStockSalesExcel,
    stockSalesFind: apiPath.retailStockSalesFind
}

export default StockSalesPath;
