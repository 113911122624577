import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PurchaseSalesDetailList from './List';
import purchaseSalesDetailPath from './config';

const PurchaseSalesDetailRoutes = () => {
    return (
        <Switch>
            <Route path={ purchaseSalesDetailPath.basePath } exact component={ PurchaseSalesDetailList } />
        </Switch>
    );
};

export default PurchaseSalesDetailRoutes;
