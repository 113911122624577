import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PaymentList from './List';
import PaymentAction from './Action';
import paymentPath from './config';

const CoaRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ paymentPath.base } exact component={ PaymentList } />
                <Route path={ paymentPath.new } exact component={ PaymentAction } />
                <Route path={ paymentPath.detail } exact component={ PaymentAction } />
            </Switch>
        </React.Fragment>
    );
};

export default CoaRoutes;
