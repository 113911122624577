import React from 'react';
import { useGlobalState } from 'state-pool';

import TrxUpdate from '../pages/dashboard/TransactionUpdate';

const Dashboard = () => {
    const [isShowRetailCash, ,] = useGlobalState('isShowRetailCash');
    const [isShowBankCash, ,] = useGlobalState('isShowBankCash');

    return(
        <React.Fragment>
            <div className="row mb-4">
                <div className="col-lg-6 mb-4 mb-lg-0">
                    <TrxUpdate />
                </div>
            </div>

            <div className="row mb-4">
                {
                    isShowBankCash &&
                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="card">
                            <div className="card-header">
                                <h2 className="h6 text-uppercase mb-0">Unit BRILINK</h2>
                            </div>
                            <div className="card-body">
                                
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-chart-bar"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Transaksi sd Hari Ini</span><span className="dot dot-sm ml-2 bg-violet"></span></h6>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-violet">
                                        <h5>0</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-calendar-alt"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Transaksi Bulan Ini</span><span className="dot dot-sm ml-2 bg-green"></span></h6>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-green">
                                        <h5>0</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-calendar-check"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Transaksi Hari Ini.</span><span className="dot dot-sm ml-2 bg-blue"></span></h6>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-blue">
                                        <h5>0</h5>
                                    </div>
                                </div>  
                                
                            </div>
                        </div>
                    </div>
                }
                {
                    isShowRetailCash &&
                    <div className="col-lg-6 mb-4 mb-lg-0 pl-lg-0">
                        <div className="card">
                            <div className="card-header">
                                <h2 className="h6 text-uppercase mb-0">Unit Pupuk dan Alat Pertanian</h2>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-chart-bar"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Penjualan sd Hari Ini</span><span className="dot dot-sm ml-2 bg-violet"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-violet">
                                        <h5>0</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-calendar-alt"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Penjualan Bulan Ini</span><span className="dot dot-sm ml-2 bg-green"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-green">
                                        <h5>0</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-calendar-check"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Penjualan Hari Ini.</span><span className="dot dot-sm ml-2 bg-blue"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-blue">
                                        <h5>0</h5>
                                    </div>
                                </div>

                                <hr />

                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-chart-bar"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Pembelian sd Hari Ini</span><span className="dot dot-sm ml-2 bg-violet"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-violet">
                                        <h5>0</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-calendar-alt"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Pembelian Bulan Ini</span><span className="dot dot-sm ml-2 bg-green"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-green">
                                        <h5>0</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-calendar-check"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Pembelian Hari Ini.</span><span className="dot dot-sm ml-2 bg-blue"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-blue">
                                        <h5>0</h5>
                                    </div>
                                </div>  

                                <hr />

                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-chart-bar"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Kerugian sd Hari Ini</span><span className="dot dot-sm ml-2 bg-violet"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-violet">
                                        <h5>0</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-calendar-alt"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Kerugian Bulan Ini</span><span className="dot dot-sm ml-2 bg-green"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-green">
                                        <h5>0</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-4 flex-column flex-sm-row">
                                    <div className="left d-flex align-items-center">
                                        <div className="icon icon-lg shadow mr-3 text-gray"><i className="far fa-calendar-check"></i></div>
                                        <div className="text">
                                            <h6 className="mb-0 d-flex align-items-center"> <span>Kerugian Hari Ini.</span><span className="dot dot-sm ml-2 bg-blue"></span></h6>
                                            <small className="text-gray">Jumlah Transaksi</small>
                                        </div>
                                    </div>
                                    <div className="right ml-5 ml-sm-0 pl-3 pl-sm-0 text-blue">
                                        <h5>0</h5>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default Dashboard;