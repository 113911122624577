import apiPath from '../../../config/api';

const PurchaseSalesDetailPath = {
    title: "Toko Material - Pembelian dan Penjualan Detail",
    basePath: "/material/jual-beli-detail",
    base: apiPath.retailPurchaseSalesDetail,
    purchaseSalesDetailExcel: apiPath.retailPurchaseSalesDetailExcel,
    purchaseSalesDetailFind: apiPath.retailPurchaseSalesDetailFind
}

export default PurchaseSalesDetailPath;
