import apiPath from '../../../config/api';

const StockPath = {
    title: "Toko Teknik - Saldo Awal",
    basePath: "/technique/stok",
    newPath: "/technique/stok/baru",
    detailPath: "/technique/stok/opsi/:id",
    api: apiPath.retailStock,
    generated: apiPath.retailStockGenerated,
    excel: apiPath.retailStockExcel,
    goods: apiPath.retailStockGoods
}

export default StockPath;
