import apiPath from '../../../config/api';

const AddressPath = {
    title: "Modul Alamat", 
    empPath: "/master/karyawan",
    custPath: "/master/pelanggan",
    empNewAddrPath: "/master/karyawan/:parentId/alamat/baru",
    custNewAddrPath: "/master/pelanggan/:parentId/alamat/baru",
    emplAddrDetailPath: "/master/karyawan/:parentId/alamat/detail/:id",
    custAddrDetailPath: "/master/pelanggan/:parentId/alamat/detail/:id",
    baseApi: apiPath.masterAddr,
    addrLocFilter: apiPath.masterAddrLocFilter
}

export default AddressPath;
