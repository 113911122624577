import React, { useState, useEffect } from 'react';
import { Settings, DateTime } from 'luxon';
import { formatDate } from 'react-day-picker/moment';

import Label from '../../../components/label';
import DayPicker from '../../../components/day-picker';
import FilterButton from '../../../components/filter-button';
import TextNumber from '../../../components/text-number';
import LoaderType from '../../../components/loader-type';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import { getDateApiParam } from '../../../helper/util';
import dailySumPath from './config';

const List = () => {

    Settings.defaultLocale = 'id';

    const [isLoad, setIsLoad] = useState(true);

    const [dailySum, setDailySum] = useState([]);

    const [currDate,] = useState(DateTime.local());

    const dailySumFilterEntity = {

        invoiceNo: "",
        trxDate: undefined,
        trxDateFrom: undefined,
        customerId: 0,
        customerName: "",
        settled: ""

    }

    const [dailySumFilter, setDailySumFilter] = useState(dailySumFilterEntity);

    const onDayChange = day => {

        setDailySumFilter({ 
            ...dailySumFilter,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    };

    const onDayFromChange = day => {

        setDailySumFilter({ 
            ...dailySumFilter,
            trxDateFrom: formatDate(day, constant.DEFAULT_DATE),
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    };

    const getParams = () => {

        const params = { 
            params: { 
                'trx-date': dailySumFilter.trxDate,
                'trx-date-from': dailySumFilter.trxDateFrom, 
            } 
        };

        return params;
    }

    const onSubmit = e => {

        e.preventDefault();

        setIsLoad(true);

        const params = getParams();

        getDailySum(params).then(result => {

            setDailySum(result);

            setIsLoad(false);

        });

    }

    const getDailySum = async(params) => {

        const result = await getData(dailySumPath.base, params);

        return result;

    }

    const onReset = (e) => {

        e.preventDefault();

        setIsLoad(true);

        setDailySumFilter(dailySumFilterEntity);

        const params = { 
            params: { 
                'inv-prefix':'T',
                'trx-date': getDateApiParam(currDate),
                'trx-date-from': getDateApiParam(currDate)
            } 
        };

        getDailySum(params).then(result => {

            setDailySum(result);

            setIsLoad(false);

        });

    }

    useEffect(() => { 
        
        const params = { 
            params: { 
                'inv-prefix':'T',
                'trx-date': getDateApiParam(currDate),
                'trx-date-from': getDateApiParam(currDate)
            } 
        };

        getDailySum(params).then(result => {

            setDailySum(result);

            setIsLoad(false);

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ dailySumPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Penjualan Dari" />
                                    <DayPicker id="trx-date" name="trxDateFrom" onDayChange={ onDayFromChange } valueDate={ dailySumFilter.trxDateFrom } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Penjualan Ke" />
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } valueDate={ dailySumFilter.trxDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ dailySumPath.newPath } newLabel="Tambah Data Penjualan" onClick={ onReset }/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    isLoad &&
                    <LoaderType />
                }

                {
                    !isLoad && dailySum.length > 0 &&
                    <>
                        <br />
                                
                        <div className="col-lg-12 mb-4">
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Deskripsi</th>
                                                <th>Tipe Pembayaran</th>
                                                <th>Total Penjualan (Rp)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dailySum.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ item.description }</td>
                                                            <td>{ item.name }</td>
                                                            <td><TextNumber value={ item.total } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
