import apiPath from '../../../config/api';

const PurchasePath = {
    title: "Toko Material - Modul Pembelian",
    basePath: "/material/pembelian",
    newPath: "/material/pembelian/baru",
    detailPath: "/material/pembelian/opsi/:id",
    newUnit: "/master/satuan/baru",
    base: apiPath.retailPurchase,
    baseHistory: apiPath.retailPurchaseHistory,
    custFind: apiPath.masterCustFind,
    unitFilter: apiPath.masterUnitFilter,
    coaGns: apiPath.masterCoaGns,
    apArFilterCustId: apiPath.accountApArCustIdFilter,
    gnsNmFilter: apiPath.masterGnsNmFilter,
    gnsCodeFilter: apiPath.masterGnsCodeFilter,
    gns: apiPath.masterGns,
    gnsFind: apiPath.masterGnsFind,
    excel: apiPath.retailPurchaseExcel,
    paymentMethodFind: apiPath.masterPaymentFind,
    unit: apiPath.masterUnit
}

export default PurchasePath;
