import React from 'react';

import SubmitButton from './submit-button';
import LinkButton from './link-button';
import ClickButton from './click-button';

const FilterButton = ({ to, newLabel, onClick, onExport, isExport }) => {

    return (
        <div className="btn-group d-flex">
            <SubmitButton className="p-2 flex-fill btn btn-info" submitLabel="Cari" />
            <LinkButton to={ to } className="p-2 flex-fill btn btn-primary" label={ newLabel } />
            {
                isExport &&
                <ClickButton onClick={ onExport } className="p-2 flex-fill btn btn-success" label="Excel" />
            }
            <ClickButton onClick={ onClick } />
        </div>
    );
    
}

export default FilterButton;
