import React from 'react';
import Loader from "react-loader-spinner";

const Bars = () => {

    return(
        <span className="row">
            <span className="col-md-12 text-center">
                <Loader
                    type="Bars"
                    color="#4680ff"
                    height={20}
                    width={20}
                    timeout={0}
                />
            </span>
        </span>
    );
}

export default Bars;
