import apiPath from '../../../config/api';

const StockPath = {
    title: "Toko Material - Saldo Awal",
    basePath: "/material/stok",
    newPath: "/material/stok/baru",
    detailPath: "/material/stok/opsi/:id",
    api: apiPath.retailStock,
    generated: apiPath.retailStockGenerated,
    excel: apiPath.retailStockExcel,
    goods: apiPath.retailStockGoods
}

export default StockPath;
