import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useGlobalState } from 'state-pool';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import settingPath from './config';

const Action = () => {

    const [, , setTlock] = useGlobalState('tlock');

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [setting, setSetting] = useState({
        id: 0,
        description: '',
        key: '',
        value: ''
    });

    const onReset = e => {

        e.preventDefault();

        setSetting(setting => ({ 
            ...setting,
            id: 0,
            description: '',
            key: '',
            value: ''
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setSetting(setting => ({ 
            ...setting,
            [name]: value
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postSetting = async () => {

            const result = await postData(settingPath.api, setting);

            if (!_.isEmpty(result)) {

                onSuccess(`Setting ${result.description} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putSetting = async () => {

            const result = await putData(`${settingPath.api}/${setting.id}`, setting);

            if (!_.isEmpty(result)) {
                
                onSuccess(`Bank ${result.description} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (setting.id === 0 && setting.description) postSetting();
        else putSetting();

        if (setting.key === 'tlock') setTlock(setting.value);
        
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
    
                setSetting(state);
                
                setTitleInfo(`Detail: ${state.description}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah Pengaturan");
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (setting.description) setIsDisabledSave(false);

    }, [setting.description]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ settingPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="description" isCol={ true } text="Description" isMandatory={ true } value={ setting.description } />
                                <div className="col-md-9">
                                    <input 
                                        id="description"
                                        type="text" 
                                        className="form-control" 
                                        name="description"
                                        value={setting.description}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="key" isCol={ true } text="Key" isMandatory={ true } value={ setting.key } />
                                <div className="col-md-9">
                                    <input 
                                        id="key"
                                        type="text" 
                                        className="form-control" 
                                        name="key"
                                        value={setting.key}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="value" isCol={ true } text="Value" isMandatory={ true } value={ setting.value } />
                                <div className="col-md-9">
                                    <input 
                                        id="value"
                                        type="text" 
                                        className="form-control" 
                                        name="value"
                                        value={setting.value}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
