import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import _ from 'lodash';

import Label from '../../../components/label';
import InputNumeral from '../../../components/input-numeral';
import ActionButton from '../../../components/action-button';
import { putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import stockPath from './config';

const Action = () => {

    const data = useLocation();
    const refData = useRef(data);
    const history = useHistory();

    const [titleInfo, setTitleInfo] = useState("");

    const [stockItem, setStockItem] = useState({
        'code': '',
        'mgasId': 0,
        'name': '',
        'periode': undefined,
        'stock': 0,
        'stockId': 0,
        'description': ''
    });

    const onStockChange = values => {

        const { value } = values;

        setStockItem(stock => ({
            ...stock,
            stock: parseFloat(value)
        }));
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const put = async () => {

            const result = await putData(`${stockPath.api}/${stockItem.stockId}`, stockItem);

            if (!_.isEmpty(result)) {
                
                onSuccess(`Alamat ${result.location} berhasil di perbaharui`);

                history.goBack();

            }
        }

        put();
        
    }


    useEffect(() => {

        function init() {

            const state = refData.current.state;

            if (state) {

                setStockItem(state);
                
                setTitleInfo(`Detail: ${state.name}`);
    
            } 
        }
    
        init();

    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ stockPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-6 border-right">
                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="periode" text="Periode" />
                                <input 
                                    id="periode" 
                                    type="text" 
                                    className="form-control"
                                    value={ DateTime.fromISO(stockItem.periode).toFormat('dd-LLLL-yyyy') }
                                    disabled />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="kategori" text="Kategori" />
                                <input 
                                    id="kategori" 
                                    type="text" 
                                    className="form-control"
                                    value={ stockItem.description }
                                    disabled />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6">
                                <Label htmlFor="kode" text="Kode Barang" />
                                <input 
                                    id="kode" 
                                    type="text" 
                                    className="form-control"
                                    value={ stockItem.code }
                                    disabled />
                            </div>
                            <div className="col-md-6">
                                <Label htmlFor="name" text="Nama Barang" />
                                <input 
                                    id="name" 
                                    type="text" 
                                    className="form-control"
                                    value={ stockItem.name }
                                    disabled />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <form onSubmit={ e => onSubmit(e)}>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="qty" text="Stock" isMandatory={ true } value={ stockItem.stock } />
                                    <InputNumeral 
                                        id="qty"
                                        onValueChange={ onStockChange }
                                        value={ stockItem.stock } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label />
                                    <ActionButton 
                                        isSave={ true }
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default Action;
