import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import constant from '../config/constant';

const LinkButton = (props) => {

    const [className, setClassName] = useState('btn btn-info');
    const [label, setLabel] = useState(constant.DETAIL);

    useEffect(() => {

        if (props.className) setClassName(props.className);

        if (props.label) setLabel(props.label);

    }, [props]);

    return(<Link to={ props.to } className={ className }>{ label }</Link>);
}

export default LinkButton;
