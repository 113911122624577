import React, { useEffect, useState } from 'react';
import Async from 'react-select/async';

import { getData } from '../helper/request-response';
import constant from '../config/constant';

const Select = ({ api, paramFilter, entityId, textFilter, value, onChange }) => {

    const [option, setOption] = useState([]); 

    const [filter, setFilter] = useState('keyword');

    const [field, setField] = useState('label');

    const filterOption = (val) => option.filter(o => o[field].toLowerCase().includes(val.toLowerCase()))

    const getSourceData = val => new Promise((resolve) => {

        const getResult = async() => {

            const param = {
                params: { 
                    [filter]: val,
                    'entity-id': entityId
                }
            };
    
            const result = await getData(api, param);

            return result;
        }

        const result = getResult();

        resolve(result);
    });

    const asyncOption = val => new Promise((resolve) => {

        getSourceData(val).then(data => {

            setOption(data);

            resolve(filterOption(val));

        });
    });

    useEffect(() => {

        getSourceData('').then(data => {

            setOption(data);

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (paramFilter) setFilter(paramFilter);

        if (textFilter) setField(textFilter);

    }, [paramFilter, textFilter]);

    return (
        <Async
            key={ entityId }
            cacheOptions
            defaultOptions={ option }
            loadOptions={ asyncOption }
            onChange={ opt => onChange(opt) }
            value={ value }
            noOptionsMessage={ () => constant.DATA_NOT_FOUND }
        />
    )

}

export default Select;
