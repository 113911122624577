import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import TextNumber from '../../../components/text-number';
import LoaderType from '../../../components/loader-type';
import DayPicker from '../../../components/day-picker';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import banktrxPath from './config';

const List = () => {

    const [rows, ,] = useGlobalState('rows');

    const rowsRef = useRef(rows);

    const [banktrxs, setBankTrxs] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    const filterEntity = {
        'invoiceNo': '',
        'trxDate': '',
        'customerName': '',
        'rows': rows
    }

    const [banktrxFilter, setFilterBankTrx] = useState(filterEntity);

    const getDetailPath = (id) => banktrxPath.detailPath.replace(":id", id);

    const getFilter = useCallback((filter) => {

        const params = { 
            'params': { 
                'invoiceNo': _.get(filter, 'invoiceNo'), 
                'trxDate': _.get(filter, 'trxDate'),
                'customerName': _.get(filter, 'customerName'),
                'rows': _.get(filter, 'rows')
            } 
        };

        return params;

    }, []);

    const onInputChange = e => {

        const { name, value } = e.target;

        setFilterBankTrx(trx => ({ 
            ...trx,
            [name]: value
        }));
    };

    const onDayChange = day => {

        setFilterBankTrx(trx => ({ 
            ...trx,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    const getBankTrxs = params => {

        setIsLoad(true);

        getData(banktrxPath.baseApi, params).then(result => {

            setBankTrxs(result);
            setIsLoad(false);

        });

    }

    const onSubmit = e => {

        e.preventDefault();

        const params = getFilter({ 'invoiceNo': banktrxFilter.invoiceNo, 'trxDate': banktrxFilter.trxDate, 'customerName': banktrxFilter.customerName, 'rows': banktrxFilter.rows });
        getBankTrxs(params);

    };

    const onReset = e => {

        e.preventDefault();

        setFilterBankTrx(filterEntity);

        const params = getFilter({ 'rows': banktrxFilter.rows });
        getBankTrxs(params);

    }

    useEffect(() => { 

        const params = getFilter({ 'rows': rowsRef.current });
        getBankTrxs(params);

     }, [getFilter]);
    

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{banktrxPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <Label htmlFor="banktrx-invoiceNo" isCol={ true } text="No Transaski" />
                                <div className="col-md-9">
                                    <input 
                                        id="banktrx-invoiceNo" 
                                        type="text" 
                                        className="form-control"
                                        name="invoiceNo"
                                        value={ banktrxFilter.invoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="trx-date" isCol={ true } text="Tanggal Pembelian" />
                                <div className="col-md-9">
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="banktrxtype-customerName" isCol={ true } text="Nama Pelanggan" />
                                <div className="col-md-9">
                                    <input 
                                        id="banktrxtype-customerName" 
                                        type="text" 
                                        className="form-control"
                                        name="customerName"
                                        value={ banktrxFilter.customerName }
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ banktrxPath.newPath } newLabel="Tambah Transaksi Baru" onClick={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Transaksi Baru" entity="Transaksi" />
                    </div>
                </div>
                
                <br />

                { isLoad && <LoaderType /> }
                
                {
                    !isLoad && banktrxs.length > 0 &&
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Jenis Transaksi</th>
                                        <th>No Faktur</th>
                                        <th>Tanggal</th>
                                        <th>Nama Pelanggan</th>
                                        <th>Jumlah</th>
                                        <th>Status</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        banktrxs.map((banktrx, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ banktrx.trxTypeName }</td>
                                                    <td>{ banktrx.invoiceNo }</td>
                                                    <td>{ `${formatDate(banktrx.trxDate, 'LL', constant.LOCALE)}` }</td>
                                                    <td>{ banktrx.custName }</td>
                                                    <td><TextNumber value={ banktrx.amount } /></td>
                                                    <td>{ banktrx.isCommit === 1 ? constant.COMMIT : constant.VOID }</td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(banktrx.id), state: banktrx} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div> 
                }

            </div>
        </React.Fragment>
    );
};

export default List;
