import apiPath from '../../../config/api';

const PurchaseSalesDetailPath = {
    title: "Toko Teknik - Pembelian dan Penjualan",
    basePath: "/technique/jual-beli-detail",
    base: apiPath.retailPurchaseSalesDetail,
    purchaseSalesDetailExcel: apiPath.retailPurchaseSalesDetailExcel,
    purchaseSalesDetailFind: apiPath.retailPurchaseSalesDetailFind
}

export default PurchaseSalesDetailPath;
