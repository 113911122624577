import apiPath from '../../../config/api';

const PurchaseSalesPath = {
    title: "Toko Teknik - Pembelian dan Penjualan",
    basePath: "/technique/jual-beli",
    base: apiPath.retailPurchaseSales,
    purchaseSalesExcel: apiPath.retailPurchaseSalesExcel,
    purchaseSalesFind: apiPath.retailPurchaseSalesFind
}

export default PurchaseSalesPath;
