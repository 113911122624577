import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';

import { DateTime } from 'luxon';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import InputNumeral from '../../../components/input-numeral';
import { postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import receivablePath from './config';

const Action = () => {
    
    const data = useLocation();
    
    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [isNew, setIsNew] = useState(true);
    
    const [titleInfo, setTitleInfo] = useState("");

    const [selectedCustomer, setSelectedCustomer] = useState(constant.DEFAULT_SELECTED);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(constant.DEFAULT_SELECTED);

    const receivableEntity = {
        id: 0,
        apArId: 0,
        trxDate: DateTime.local(),
        invoiceNo: null,
        invoiceLineNo: 0,
        amount:0,
        masterCustomerId: 0,
        loanBalance:0,
        outstanding:0,
        custName:"",
        isChecked: 0,
        description: "",
        paymentMethodId: 0,
        paymentMethodName: null
    }

    const [receivable, setReceivable] = useState(receivableEntity);

    const onInputChange = e => {

        const { name, value } = e.target;
        
        const receivableData = {
            ...receivable,
            [name]: value
        } 

        setReceivable(receivableData);
        
    };

    const onCheckboxChange = e => {

        if(e.target.checked){
            setReceivable(data => ({
                ...data,
                amount: receivable.loanBalance,
                isChecked: true
            }));
        }else{
            setReceivable(data => ({
                ...data,
                amount: 0,
                isChecked: false
            }));
        }
        
    }

    const onAmountChange = values => {

        const { value } = values;
        const val = parseFloat(value);

        if (receivable.loanBalance >= val){
            setReceivable(data => ({
                ...data,
                amount: val,
                outstanding: calcOutstanding(data.loanBalance, val)
            }));
        }else{
            setReceivable(data => ({
                ...data,
                amount: receivable.loanBalance,
                outstanding:0
            }));
        }

    }

     /**
     * Customer change event.
     * @param {Object} opt - Option object.
     */
    const onChangeCustomer = opt => {

        if (opt.id) {

            setReceivable(data => ({
                ...data,
                apArId: opt.apArId,
                masterCustomerId: opt.id,
                invoiceNo: "AR" + opt.invoice_no,
                loanBalance: opt.loanBalance,
                custName: opt.name,
                amount: 0,
                description: '',
                isChecked: false
            }));

            setSelectedCustomer(name => ({
                ...name,
                value: opt.invoice_no,
                label: opt.label
            }));

            setSelectedPaymentMethod(constant.DEFAULT_SELECTED);
        } 
    }

    const onChangePaymentMethod = async (opt) => {

        if (opt.id) {

            setReceivable(data => ({ 
                ...data,
                paymentMethodId: opt.id,
                paymentMethodName: opt.name
            }));

            setSelectedPaymentMethod(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onDayChange = day => {

        setReceivable({ 
            ...receivable,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }

    const onReset = e => {

        e.preventDefault();

        setReceivable(receivableEntity);

        setSelectedCustomer(constant.DEFAULT_SELECTED);

        setSelectedPaymentMethod(constant.DEFAULT_SELECTED);
    }

    const calcOutstanding = (loanBalance, amount) =>  {
        
        const outstanding = parseFloat(loanBalance) - parseFloat(amount);

        return outstanding < 0 ? 0 : outstanding;
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postReceiveable = async () => {

            const result = await postData(receivablePath.baseApi, receivable);

            if (Object.keys(result).length) {

                onSuccess(`Transaksi ${result.invoice_no} berhasil di tambahkan`);

                history.goBack();

            }
        };

        if (receivable.id === 0) postReceiveable();

    }

    useEffect(() => {

        function setReceivableState() {

            if (data.state) {

                setReceivable(data.state)

                setIsResetable(false);

                setTitleInfo(`Detail: ${data.state.invoiceNo}`);

                setSelectedCustomer(code => ({
                    ...code,
                    value: data.state.custId,
                    label: data.state.custName
                }));

                setIsNew(false);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setReceivableState();

    }, [data]);

    useEffect(() => {

        if (receivable.masterCustomerId && receivable.paymentMethodId && ( receivable.amount > 0) ) setIsDisabledSave(false);

        if (!receivable.masterCustomerId || !receivable.paymentMethodId || ( receivable.amount <= 0) ) setIsDisabledSave(true);

    }, [receivable.masterCustomerId, receivable.paymentMethodId, receivable.amount]);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{receivablePath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="account-type" text="Tanggal"/>
                                <div>
                                    <DayPicker id="trx-date" name="trxDate" valueDate={receivable.trxDate} onDayChange={onDayChange} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="cust-name" text="Pelanggan | No Invoice | Sisa Hutang" isMandatory={ true } value={ receivable.masterCustomerId } />
                                <Select
                                    id="cust-name"
                                    paramFilter="name"
                                    api={ receivablePath.custFind }
                                    onChange={ onChangeCustomer }
                                    value={ selectedCustomer }
                                />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                    <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>

                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="ar-invoice-no" text="No Invoice"/>
                                <input 
                                    id="ar-invoice-no"
                                    type="text" 
                                    className="form-control" 
                                    name="invoiceNo"
                                    value={receivable.invoiceNo}
                                    onChange={ e => onInputChange(e) } 
                                    readOnly
                                />
                            </div>
                        </div>
                        
                        {isNew &&
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label htmlFor="account-type" text="Total Hutang"/>
                                <InputNumeral 
                                    id="loanBalance"
                                    value={ receivable.loanBalance }
                                    display={ true }
                                />
                            </div>
                        </div>
                        }

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="account-type" text="Jumlah Bayar" isMandatory={ true } value={ receivable.amount } />
                                <InputNumeral 
                                    id="amount"
                                    value={ receivable.amount }
                                    onValueChange={ onAmountChange }
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="payment-method" text="Metode Pembayaran" isMandatory={ true } value={ receivable.paymentMethodName} />
                                <Select
                                    id="payment-method"
                                    paramFilter="name"
                                    api={ receivablePath.paymentFind }
                                    onChange={ onChangePaymentMethod }
                                    value={ selectedPaymentMethod }
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="description" text="Keterangan" />
                                <textarea 
                                    id="description"
                                    className="form-control" 
                                    name="description"
                                    value={receivable.description}
                                    onChange={ e => onInputChange(e) } 
                                />
                            </div>
                        </div>
                        
                        {isNew &&
                        <div className="form-group row">
                            <div className="col-md-12">
                                <div className="custom-control custom-checkbox">
                                    <input 
                                        id="pay-all"
                                        type="checkbox"
                                        className="custom-control-input"
                                        name="payAll"
                                        value={ receivable.isChecked }
                                        checked={ receivable.isChecked }
                                        onChange={ e => onCheckboxChange(e) }
                                        disabled={ !receivable.custName }
                                    />   
                                    <label htmlFor="pay-all" className="custom-control-label">Bayar Semua</label>
                                </div>
                            </div>
                        </div>
                        }

                        {isNew &&
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label htmlFor="outstanding" text="Sisa Hutang"/>
                                <InputNumeral 
                                    id="outstanding"
                                    value={ receivable.outstanding }
                                    display={ true }
                                />
                            </div>
                        </div>
                        }
                        
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label />
                                <ActionButton 
                                    isSave={ isNew }
                                    isDisabledSave={ isDisabledSave } 
                                    isReset={ isResetable } 
                                    onReset={ onReset } />
                            </div>
                        </div>

                        <Note isAction={ true } />
                    
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
