import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DateTime, Settings } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import _ from 'lodash';
import { useGlobalState } from 'state-pool';
import { Modal } from 'react-responsive-modal';

import ClickButton from '../../../components/click-button';
import LoaderType from '../../../components/loader-type';
import Label from '../../../components/label';
import Note from '../../../components/note';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import { postData, getData, deleteData, getFile } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import purchasePath from './config';
import TextNumber from '../../../components/text-number';
import { downloadFile } from '../../../helper/util';

const List = () => {

    Settings.defaultLocale = 'id';

    const [rows, ,] = useGlobalState('rows');

    const [user, ,] = useGlobalState('user');

    const [deleteRoleId, ,] = useGlobalState('deleteRoleId');

    const rowsRef = useRef(rows);

    const purchaseFilterEntity = {
        'invoiceNo': '',
        'trxDate': undefined,
        'trxDateFrom': undefined,
        'supplierId': 0,
        'supplierName': '',
        'rows': rows
    }

    const [isDelete, setIsDelete] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);

    const [isLoad, setIsLoad] = useState(true);

    const [selectedSupplier, setSelectedSupplier] = useState(constant.DEFAULT_SELECTED);

    const [purchase, setPurchase] = useState([]);

    const [purchaseItem, setPurchaseItem] = useState({});

    const [purchaseFilter, setPurchaseFilter] = useState(purchaseFilterEntity);

    const onCloseModal = () => setIsOpen(false);

    const getDetailPath = (id) => purchasePath.detailPath.replace(":id", id);

    const getFilter = useCallback((filter) => {

        const params = { 
            'params': { 
                'invoice-no': _.get(filter, 'invoiceNo'), 
                'trx-date': _.get(filter, 'trxDate'),
                'trx-date-from': _.get(filter, 'trxDateFrom'),
                'supplier-id': _.get(filter, 'supplierId'),
                'rows': _.get(filter, 'rows'),
                'inv-prefix': _.get(filter, 'inv-prefix')
            } 
        };

        return params;

    }, []);

    const onExport = e =>  {

        e.preventDefault();

        const params = { 
            params: { 
                'invoice-no': purchaseFilterEntity.invoiceNo, 
                'trx-date': purchaseFilterEntity.trxDate,
                'trx-date-from': purchaseFilterEntity.trxDateFrom, 
                'customer-id': purchaseFilterEntity.supplierId,
                'rows': purchaseFilterEntity.rows,
                'inv-prefix':'MP'
            } 
        };

        getFile(purchasePath.excel, params).then(response => downloadFile(response));

    }

    const onInputChange = e => {

        const { name, value } = e.target

        setPurchaseFilter(purchaseFilter => ({ 
            ...purchaseFilter,
            [name]: value
        }));
    };

    const onSupplierChange = (opt) => {

        if (opt.id) {

            setPurchaseFilter(purchaseFilter => ({
                ...purchaseFilter,
                supplierId: opt.id,
                supplierName: opt.name
            }));

            setSelectedSupplier(supp => ({
                ...supp,
                value: opt.id,
                label: opt.name
            }));

        }
        
    }

     const onDayFromChange = day => {

        setPurchaseFilter(purchaseFilter => ({ 
            ...purchaseFilter,
            trxDateFrom: formatDate(day, constant.DEFAULT_DATE),
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    const onDayChange = day => {

        setPurchaseFilter(purchaseFilter => ({ 
            ...purchaseFilter,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));

    };

     const getPurchases = params => {

        setIsLoad(true);

        getData(purchasePath.base, params).then(result => {

            setPurchase(result);
            setIsLoad(false);

        });

    }

    const onSubmit = (e) => {

        e.preventDefault();

        setIsLoad(true);

        const params = getFilter({ 'invoiceNo': purchaseFilter.invoiceNo, 'trxDate': purchaseFilter.trxDate, 'trxDateFrom': purchaseFilter.trxDateFrom, 'supplierId': purchaseFilter.supplierId, 'rows': purchaseFilter.rows, 'inv-prefix':'MP' });
        getPurchases(params);

    }

    const onReset = (e) => {

        e.preventDefault();

        setIsLoad(true);

        setPurchaseFilter(purchaseFilterEntity);

        setSelectedSupplier(constant.DEFAULT_SELECTED);

        const params = getFilter({ 'rows': purchaseFilter.rows, 'inv-prefix':'MP' });
        
        getPurchases(params);

    }

    const onDelete = e => {

        e.preventDefault();

        const val = parseInt(e.currentTarget.value);

        setPurchaseItem(purchase[val]);

        setIsOpen(true);

    }

    const onConfirmDelete = async(e) => {

        e.preventDefault();

        setIsLoad(true);

        let param = purchaseItem;
        param.recordType = 'DELETE';

        const historyResult = await postData(purchasePath.baseHistory, param);

        if (historyResult) {

            const params = {
                params: { 
                    'invoice-no': purchaseItem.invoiceNo,
                    'sales-invoice-no': purchaseItem.salesInvoiceNo
                }
            };
            
            const removeResult = await deleteData(purchasePath.base, params);

            if (!_.isEmpty(removeResult)) {

                onSuccess(`Pembelian ${purchaseItem.invoiceNo} berhasil di hapus`);

                const params = getFilter({ 'rows': rowsRef.current, 'inv-prefix':'MP'});
        
                getPurchases(params);

                setIsOpen(false);
            }
        }

    }

    useEffect(() => { 

        const params = getFilter({ 'rows': rowsRef.current, 'inv-prefix':'MP'});
        
        getPurchases(params);

        if (_.findIndex(deleteRoleId, f => f === user.roleId.toString()) !== undefined && _.findIndex(deleteRoleId, f => f === user.roleId.toString()) > -1) {
            setIsDelete(true);
        }
    
    }, [getFilter, user.roleId, deleteRoleId]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{purchasePath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <Modal open={ modalIsOpen } onClose={ onCloseModal } center>
                        <div className="modal-header">
                            <h5 className="modal-title">KONFIRMASI PENGHAPUSAN DATA</h5>
                        </div>

                        <div className="modal-body">
                            <table className="table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Faktur Pembelian</td>
                                        <td>:</td>
                                        <td>{purchaseItem.invoiceNo}</td>
                                    </tr>
                                    <tr>
                                        <td>Tanggal Transaksi</td>
                                        <td>:</td>
                                        <td>{`${formatDate(purchaseItem.trxDate, 'LL', constant.LOCALE)}`}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Supplier</td>
                                        <td>:</td>
                                        <td>{purchaseItem.supplierName}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer">
                            <ClickButton onClick={ onConfirmDelete } label="Hapus" className="btn btn-primary" />
                        </div>
                    </Modal>
                </div>
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="inv-no" text="No Faktur" />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        name="invoiceNo"
                                        value={ purchaseFilter.invoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="supplier" text="Supplier" />
                                    <Select 
                                        id="supplier"
                                        api={ purchasePath.custFind }
                                        entityId="6"
                                        value={ selectedSupplier }
                                        onChange={ onSupplierChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Pembelian Dari" />
                                    <DayPicker id="trx-date" name="trxDateFrom" onDayChange={ onDayFromChange } valueDate={ purchaseFilter.trxDateFrom } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Pembelian Ke" />
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } valueDate={ purchaseFilter.trxDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ purchasePath.newPath } newLabel="Tambah Data Pembelian" onClick={ onReset } isExport={ true } onExport={ onExport } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Pembelian" entity="Pembelian" />
                    </div>
                </div>

                { isLoad && <LoaderType /> }
                {
                    !isLoad && purchase.length > 0 &&
                    <>
                        <br />
                            
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>No Faktur</th>
                                                <th>Tanggal</th>
                                                <th>Nama Suplier</th>
                                                <th>Total Pembelian (Rp)</th>
                                                <th>Total Pembayaran (Rp)</th>
                                                <th>Opsi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                purchase.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ item.invoiceNo }</td>
                                                            <td>{ DateTime.fromISO(item.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                            <td>{ item.supplierName }</td>
                                                            <td><TextNumber value={ item.totalPurchase } /></td>
                                                            <td><TextNumber value={ item.payment } /></td>
                                                            {
                                                                isDelete &&
                                                                <td className="btn-group">
                                                                    <LinkButton to={ {pathname: getDetailPath(item.id), state: item} } />
                                                                    <ClickButton onClick={ onDelete } label="Hapus" className="btn btn-danger" value={ index } />
                                                                </td>
                                                            }
                                                            {
                                                                !isDelete && <td><LinkButton to={ {pathname: getDetailPath(item.id), state: item} } /></td>
                                                            }
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
