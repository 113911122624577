import apiPath from '../../../config/api';

const PaymentPath = {
    'title': 'Metode Pembayaran', 
    'base': '/master/metode-pembayaran',
    'new': '/master/payment/baru',
    'detail': '/master/payment/opsi/:id',
    'api': apiPath.masterPayment
}

export default PaymentPath;
