import { DateTime } from 'luxon';
import fileSaver from 'file-saver';

import constant from '../config/constant';

export function getSellingPrice(value) {

    const purchasePrice = parseInt(value);

    const sellingPrice = (constant.SELLING_MARGIN/100 * purchasePrice) + purchasePrice;

    return sellingPrice;
}

export function downloadFile(response) {

    const dirtyFileName = response.headers['content-disposition'];
    const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
    var  fileName = dirtyFileName.match(regex)[3];
    
    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fileSaver.saveAs(blob, fileName);
}

export function getDateApiParam(date) {
    return DateTime.fromISO(date).toFormat(constant.PARAM_DATE_FORMAT)
}

export function getDateFromJs(date) {
    return DateTime.fromJSDate(date).toFormat(constant.DEFAULT_DATE);
}

export function getISODate(date) {
    return DateTime.fromISO(date).toFormat(constant.DEFAULT_DATE);
}

export function getDisplayDateFromISO(date) {
    return DateTime.fromISO(date).toFormat(constant.DISPLAY_DATE);
}
