import React from "react";

const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      props.items &&
        <div ref={ref}>
        <table className="bill" >
          <tr>
            <td rowspan="5" style={{width:"500px", paddingRight:"10px"}}>PERMATA MATERIAL BANGUNAN PEKANBARU, <br/>
              JALAN PEMUDA NO 123, PEKANBARU<br/>
              Admin 1: 082285808188<br/>
              Admin 2: 081371660956
            </td>
            <td style={{width:"500px"}}>
              <table>
                <tr>
                  <td>Kepada Yth,</td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>:</td>
                  <td>{props.trxDate}</td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{props.customerName}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{props.customerAddress}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <br/>

        { props.rePrint === 1 ?
        <p className="center-text">
          <u>SURAT JALAN</u><br/>
          No. {props.invoiceNo}
        </p>
        :
        <p className="center-text">
          <u>FAKTUR PENJUALAN</u><br/>
          No. {props.invoiceNo}
        </p>
        }
        
        <br/>

        { props.rePrint === 1 ?
        <table className="bord">
            <thead>
                <tr>
                    <td style={{width:"100px"}}>QTY</td>
                    <td style={{width:"600px"}}>NAMA BARANG</td>
                    <td style={{width:"300px"}}>KETERANGAN</td>
                </tr>
            </thead>
              {
                  props.items.map((item, index) => {
                      return(
                          <tbody>
                              <tr key={ index }>
                                  <td className="quantity">{ item.qty +" "+ item.unit }</td>
                                  <td className="description">{ item.name }</td>
                                  <td></td>
                              </tr>
                          </tbody>
                      );
                  })
              }
        </table>
        :
        <table className="bord">
            <thead>
                <tr>
                    <td style={{width:"100px"}}>QTY</td>
                    <td style={{width:"500px"}}>NAMA BARANG</td>
                    <td style={{width:"200px"}}>@ HARGA</td>
                    <td style={{width:"200px"}}>JUMLAH</td>
                </tr>
            </thead>
              {
                  props.items.map((item, index) => {
                      return(
                          <tbody>
                              <tr key={ index }>
                                  <td className="quantity">{ item.qty +" "+ item.unit }</td>
                                  <td className="description">{ item.name }</td>
                                  <td className="price"><p class="alignright">{item.sellingPrice.toLocaleString()}</p></td>
                                  <td className="total"><p class="alignright">{(item.qty * item.sellingPrice).toLocaleString()}</p></td>
                              </tr>
                          </tbody>
                      );
                  })
              }
              <tbody>
                <tr>
                  <td colspan="3">Total</td>
                  <td>{props.totalSales.toLocaleString()}</td>
                </tr>
            </tbody>
        </table>
        }

        <br/>
        <br/>
        
        <table>
          <tr>
            <td style={{width:"500px"}}>
              <table>
                <tr>
                  <td>Note:</td>
                </tr>
                <tr>
                  <td>Barang Sudah Diterima dengan Baik dan Cukup<br/> { props.note } <br/></td>
                </tr>
                <tr>
                  <td>Mohon di Transfer ke Rekening: <br/>
                      Bank Mandiri: 900.000.955.916.3<br/>
                      Bank BCA: 1271.353.222<br/>
                      Bank BRI: 1098.0100.0753.566<br/>
                      a.n: JOKO AJI DARMAWANDI
                  </td>
                </tr>
              </table>
            </td>
            <td style={{width:"500px", verticalAlign:"baseline"}}>
              <table>
                <tr>
                <td style={{width:"250px"}}>Dikirim Oleh,</td>
                <td style={{width:"250px"}}>Diterima Oleh,</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Tanggal:</td>
                  <td>Tanggal:</td>
                </tr>
              </table>
            </td>
            
          </tr>
        </table>

        </div>
      
    );
})

export default ComponentToPrint;