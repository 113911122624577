import React from 'react';

import ProtectedRoute from './ProtectedRoute';

import Dashboard from '../pages/Dashboard';
import RoleRoute from '../pages/admin/role/Route';
import UserRoute from '../pages/admin/user/Route';
import MenuRoute from '../pages/admin/menu/Route';
import MenuAccessRoute from '../pages/admin/menu-access/Route';
import BuRoute from '../pages/master/business-unit/Route';
import CoaRoute from '../pages/master/coa/Route';
import CustomerTypeRoute from '../pages/master/customer-type/Route';
import CustomerRoute from '../pages/master/customer/Route';
import UnitRoute from '../pages/master/unit/Route';
import GoodsAndServiceRoute from '../pages/master/goods-and-services/Route';
import DesignationRoute from '../pages/master/designation/Route';
import EmployeeRoute from '../pages/master/employee/Route';
import AddressRoute from '../pages/master/address/Route';
import RetributionRoute from '../pages/master/retribution/Route';
import VehiclesRoute from '../pages/master/vehicles/Route';
import BankRoute from '../pages/master/bank/Route';
import PaymentRoute from '../pages/master/payment/Route';
import BankAccountRoute from '../pages/master/bank-account/Route';
import BankTrxTypeRoute from '../pages/brilink/trx-type/Route';
import BankTrxRoute from '../pages/brilink/trx/Route';
import MaterialSalesRoute from '../pages/material/sales/Route';
import MaterialPurchaseRoute from '../pages/material/purchase/Route';
import MaterialLossesRoute from '../pages/material/loss/Route';
import MaterialStockSalesRoute from '../pages/material/stock-sales/Route';
import MaterialStockBalance from '../pages/material/stock/Route';
import MaterialPurchaseSalesRoute from '../pages/material/purchase-sales/Route';
import MaterialPurchaseSalesDetailRoute from '../pages/material/purchase-sales-detail/Route';
import MaterialDailySummaryRoute from '../pages/material/daily-summary/Route';
import TechniqueSalesRoute from '../pages/technique/sales/Route';
import TechniquePurchaseRoute from '../pages/technique/purchase/Route';
import TechniqueLossesRoute from '../pages/technique/loss/Route';
import TechniqueStockSalesRoute from '../pages/technique/stock-sales/Route';
import TechniqueStockBalance from '../pages/technique/stock/Route';
import TechniquePurchaseSalesRoute from '../pages/technique/purchase-sales/Route';
import TechniquePurchaseSalesDetailRoute from '../pages/technique/purchase-sales-detail/Route';
import TechniqueDailySummaryRoute from '../pages/technique/daily-summary/Route';
import PoPurhcaseRoute from '../pages/palmoil/purchase/Route';
import PoSalesRoute from '../pages/palmoil/sales/Route';
import PoCashOutRoute from '../pages/palmoil/cashout/Route';
import PoRetrRoute from '../pages/palmoil/retribution/Route';
import PoHarvestRoute from '../pages/palmoil/harvest/Route';
import PoSalesPurchase from '../pages/palmoil/sales-purchase/Route';
import PoLoad from '../pages/palmoil/load/Route';
import PoCashin from '../pages/palmoil/sales-cashin/Route';
import ApArHeaderRoute from '../pages/account/ap-ar-header/Route';
import PayableRoute from '../pages/account/payable/Route';
import ReceivableRoute from '../pages/account/receivable/Route';
import MiscExpRoute from '../pages/account/misc-exp/Route';
import CashLoanRoute from '../pages/account/cash-loan/Route';
import RentTrxRoute from '../pages/rental/trx/Route';
import SaveDebRoute from '../pages/account/saving-debit/Route';
import SaveCredRoute from '../pages/account/saving-credit/Route';
import CustSaldoDetailRoute from '../pages/account/cust-saldo-detail-report/Route';
import CustTrxDetailRoute from '../pages/account/cust-trx-detail-report/Route';
import TaxRoute from '../pages/account/tax-report/Route';
import AccTrxRoute from '../pages/account/transaction-report/Route';
import ProfitLossRoute from '../pages/account/loss-profit/Route';
import AccMonitoringRoute from '../pages/account/monitoring/Route';
import AccChartRoute from '../pages/account/chart/Route';
import ApArSumRoute from '../pages/account/ap-ar-summary-report/Route';
import CashTrfRoute from '../pages/cash/cash-trf/Route';
import CashRetailRoute from '../pages/cash/retail-cash/Route';
import CashPoRoute from '../pages/cash/po-cash/Route';
import CashPbdoRoute from '../pages/cash/pbdo-cash/Route';
import CashAulia from '../pages/cash/aulia-cash/Route';
import CashBank from '../pages/cash/brilink/Route';
import BankBalance from '../pages/cash/bank-balance/Route';
import Setting from '../pages/master/setting/Route';
import Balance from '../pages/dashboard/Balance';
import Footer from './Footer';


const Content = () => {
    return(
        <div className="page-holder w-100 d-flex flex-wrap">
            
            <div className="container-fluid px-xl-3">
                <section className="py-3">
                    <div className="row">
                        <Balance />
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mb-2">
                            
                            <ProtectedRoute path="/dashboard" exact component={Dashboard} />

                            <div className="card">    
                                <RoleRoute />
                                <UserRoute />
                                <MenuRoute />
                                <MenuAccessRoute />
                                <BuRoute />
                                <CoaRoute />
                                <CustomerTypeRoute />
                                <CustomerRoute />
                                <UnitRoute />
                                <GoodsAndServiceRoute />
                                <DesignationRoute />
                                <EmployeeRoute />
                                <AddressRoute />
                                <RetributionRoute />
                                <VehiclesRoute />
                                <BankRoute />
                                <PaymentRoute />
                                <BankAccountRoute />
                                <BankTrxTypeRoute />
                                <BankTrxRoute />
                                <BankBalance />
                                <MaterialPurchaseRoute />
                                <MaterialSalesRoute />
                                <MaterialLossesRoute />
                                <MaterialStockSalesRoute />
                                <MaterialStockBalance />
                                <MaterialPurchaseSalesRoute />
                                <MaterialPurchaseSalesDetailRoute />
                                <MaterialDailySummaryRoute />
                                <TechniquePurchaseRoute />
                                <TechniqueSalesRoute />
                                <TechniqueLossesRoute />
                                <TechniqueStockSalesRoute />
                                <TechniqueStockBalance />
                                <TechniquePurchaseSalesRoute />
                                <TechniquePurchaseSalesDetailRoute />
                                <TechniqueDailySummaryRoute />
                                <PoPurhcaseRoute />
                                <PoSalesRoute />
                                <PoCashOutRoute />
                                <PoRetrRoute />
                                <PoHarvestRoute />
                                <PoSalesPurchase />
                                <PoLoad />
                                <PoCashin />
                                <ApArHeaderRoute />
                                <PayableRoute />
                                <ReceivableRoute />
                                <MiscExpRoute />
                                <CashTrfRoute />
                                <CashRetailRoute />
                                <CashPoRoute />
                                <CashPbdoRoute />
                                <CashAulia />
                                <CashLoanRoute />
                                <CashBank />
                                <RentTrxRoute />
                                <SaveDebRoute />
                                <SaveCredRoute />
                                <CustSaldoDetailRoute />
                                <AccTrxRoute />
                                <ApArSumRoute />
                                <ProfitLossRoute />
                                <AccMonitoringRoute />
                                <AccChartRoute />
                                <CustTrxDetailRoute />
                                <TaxRoute />
                                <Setting />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        
            <Footer />
      </div>
    );
};

export default Content;
