import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import paymentPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [payment, setPayment] = useState({
        id: 0,
        name: ''
    });

    const onReset = e => {

        e.preventDefault();

        setPayment(payment => ({ 
            ...payment,
            id: 0,
            name: ''
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setPayment(coa => ({ 
            ...coa,
            [name]: value
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postPayment = async () => {

            const result = await postData(paymentPath.api, payment);

            if (!_.isEmpty(result)) {

                onSuccess(`Metode Pembayaran ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putPayment = async () => {

            const result = await putData(`${paymentPath.api}/${payment.id}`, payment);

            if (!_.isEmpty(result)) {
                
                onSuccess(`Metode Pembayaran ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (payment.id === 0 && payment.name) postPayment();
        else putPayment();
        
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
    
                setPayment(state);
                
                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah Metode Pembayaran");
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (payment.name) setIsDisabledSave(false);

    }, [payment.name]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ paymentPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="payment-name" isCol={ true } text="Nama" isMandatory={ true } value={ payment.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="payment-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={payment.name}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
