import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import { useReactToPrint } from 'react-to-print';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import Label from '../../../components/label';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import ClickButton from '../../../components/click-button';
import { getData, postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import salesPath from './config';
import ComponentToPrint from './print.js';

const Action = () => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [, , setRetailBalance] = useGlobalState('retailBalance');

    const [tlock, ,] = useGlobalState('tlock');

    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isDisabledAdd, setIsDisabledAdd] = useState(true);

    const [isRePrint, setIsRePrint] = useState(true);

    const [isPrint, setIsPrint] = useState(true);

    const [idxItem, setIdxItem] = useState(-1);

    const [selectedCustName, setSelectedCustName] = useState(constant.DEFAULT_SELECTED);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(constant.DEFAULT_SELECTED);

    const [selectedGnsName, setSelectedGnsName] = useState(constant.DEFAULT_SELECTED);

    const [sales, setSales] = useState({
        id: 0,
        invoiceNo: `MS${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
        trxDate: DateTime.local(),
        lineNo: 0,
        note: "",
        isCommit: true,
        customerId: 0,
        customerName: "",
        totalSales: 0,
        payment: 0,
        outstanding: 0,
        apArId: 0,
        items:[],
        paymentMethodId: 0,
        paymentMethodName:"",
        customerAddress:"",
        customerPhoneNo:""
    });

    const gnsEntity = {
        id: 0,
        code: '',
        name: '',
        purchasePrice: 0,
        sellingPrice: 0,
        sellingPriceOrigin: 0,
        qty: 0,
        stock: 0,
        isSubtrahend: false,
        unitId: 0,
        businessUnitId: 2,
        coaId: 0,
        unit: ''
    }

    const [gns, setGns] = useState(gnsEntity);

    const resetGns = () => {

        setGns(gnsEntity);

        setSelectedGnsName(name => ({
            ...name,
            label: '',
            value: ''
        }));

    }

    const setGnsState = opt => {

        setGns(gns => ({
            ...gns,
            id: opt.id,
            code: opt.code,
            name: opt.name,
            purchasePrice: opt.purchasePrice,
            sellingPrice: sales.customerName.toLocaleLowerCase() === 'proyek' ? opt.purchasePrice : opt.sellingPrice,
            sellingPriceOrigin: opt.sellingPrice,
            stock: opt.stock,
            isSubtrahend: opt.isSubtrahend,
            unitId: opt.unitId,
            businessUnitId: opt.buId,
            coaId: opt.coaId,
            unit: opt.unit
        }));

    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setSales(sales => ({ 
            ...sales,
            [name]: value
        }));

    };

    const onGnsNameChange = opt => {

        if (opt.id) {

            setGnsState(opt);
            setSelectedGnsName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onSellingPriceChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            sellingPrice: parseFloat(value)
        }));
    }

    const onQtyChange = values => {

        const { value } = values;

        const qty = parseFloat(value) > parseFloat(gns.stock) ? parseFloat(gns.stock) : parseFloat(value)

        setGns(gns => ({
            ...gns,
            qty: qty
        }));
    }

    const calculateTotal = items => {

        let total = 0;

        for(let i = 0; i < items.length; i++) {

            const qty = parseFloat(items[i].qty);
            const sellingPrice = parseFloat(items[i].sellingPrice);
            total += qty * sellingPrice;

        }

        return total;

    };

    const onAddItem = e => {

        e.preventDefault();

        const addPurchasedItem = () => {

            let gsItems = sales.items;
            gsItems.push(gns);

            return gsItems;

        }

        const updatePurchasedItem = () => {

            let gsItems = sales.items;

            for (let i = 0; i < gsItems.length; i++) {

                if (i === idxItem) {

                    gsItems[i].id = gns.id;
                    gsItems[i].code = gns.code;
                    gsItems[i].name = gns.name;
                    gsItems[i].sellingPrice = gns.sellingPrice;
                    gsItems[i].qty = gns.qty;

                    break;
                } 
            }

            return gsItems;

        }

        let gsItems = [];

        if (idxItem < 0) gsItems = addPurchasedItem();
        else gsItems = updatePurchasedItem();

        const total = calculateTotal(gsItems);

        setSales(data => ({
            ...data,
            totalSales: total,
            outstanding: total - data.payment,
            items: gsItems
        }));

        resetGns();

        setIdxItem(-1);
    }

    const onChangeCustomer = async (opt) => {

        if (opt.id) {

            setSales(data => ({ 
                ...data,
                apArId: opt.apArId,
                customerId: opt.id,
                customerName: opt.name,
                customerAddress: opt.address,
                customerPhoneNo: opt.phoneNo,
            }));

            setSelectedCustName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

     const onChangePaymentMethod = async (opt) => {

        if (opt.id) {

            setSales(data => ({ 
                ...data,
                paymentMethodId: opt.id,
                paymentMethodName: opt.name
            }));

            setSelectedPaymentMethod(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onDayChange = day => {

        setSales(data => ({ 
            ...data,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    const onViewDetail = (index) => {

        const item = sales.items[index];

        setGns(data => ({
            ...data,
            id: item.id,
            code: item.code,
            name: item.name,
            unit: item.unit,
            unitId: item.unitId,
            stock: item.stock,
            qty: item.qty,
            sellingPrice: item.sellingPrice
        }));

        setSelectedGnsName(name => ({
            ...name,
            value: item.id,
            label: item.name
        }));

        setIdxItem(index);
    }

    const onPaymentChange = values => {

        if (isRePrint) {

            const { value } = values;

            const gsItems = sales.items;

            const total = calculateTotal(gsItems);

            const outstanding = parseFloat(total) - value;

            setSales(data => ({
                ...data,
                outstanding: outstanding,
                payment: value
            }));

        }
    }

    const onSubmit = (e) => {

        e.preventDefault();

        setRetailBalance(0);

        const post = async () => {

            const result = await postData(salesPath.base, sales);

            if (!_.isEmpty(result)) {
                onSuccess(`Penjualan ${sales.invoiceNo} berhasil di tambahkan`);

                history.goBack();
            }
        };

        if (sales.id === 0 && sales.totalSales) post();
        
    }

    const setSellingPrice = e => {

        e.preventDefault();

        setGns(goods =>({
            ...goods,
            sellingPrice: gns.sellingPriceOrigin
        }));

    }

    const setPurchasePrice = e => {

        e.preventDefault();

        setGns(goods =>({
            ...goods,
            sellingPrice: gns.purchasePrice
        }));
    }

    const getSalesDetail = async (id) => {

        const result = await getData(`${salesPath.base}/${id}`);

        return result;
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
                
                setIsPrint(false);

                setSales(state);
                
                setSales(data => ({
                    ...data,
                    rePrint:1
                }));

                setIsRePrint(false);
                
                setTitleInfo(`Detail: ${state.invoiceNo}`);

                getSalesDetail(state.id)
                    .then(result => {

                        setSales(data => ({
                            ...data,
                            items: result
                        }));

                    });

                    setIsPrint(true);    

                setSelectedCustName(name => ({
                    ...name,
                    value: state.customerId,
                    label: state.customerName
                }));

                setSelectedPaymentMethod(name => ({
                    ...name,
                    value: state.paymentMethodId,
                    label: state.paymentMethodName
                }));
    
            } else {
    
                setTitleInfo("Tambah Penjualan Baru");
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (gns.id && gns.name && gns.sellingPrice && gns.qty) setIsDisabledAdd(false);
        else setIsDisabledAdd(true);

        if (sales.customerId === 1) {

            if (sales.invoiceNo && sales.customerId && sales.customerName && sales.trxDate && sales.totalSales && sales.payment && sales.paymentMethodId && sales.paymentMethodName) setIsDisabledSave(false);
            else setIsDisabledSave(true);

        } else {

            if (sales.invoiceNo && sales.customerId && sales.customerName && sales.trxDate && sales.totalSales && sales.paymentMethodId && sales.paymentMethodName) setIsDisabledSave(false);
            else setIsDisabledSave(true);

        }

    }, [sales.invoiceNo, sales.customerId, sales.customerName, sales.trxDate, sales.totalSales, sales.payment, sales.paymentMethodId, sales.paymentMethodName, gns.id, gns.name, gns.sellingPrice, gns.purchasePrice, gns.qty]);

    return(
        <React.Fragment>
            { isPrint &&
            <div>
                <ComponentToPrint 
                    invoiceNo = {sales.invoiceNo}
                    trxDate={`${formatDate(sales.trxDate, 'LL', constant.LOCALE)}`}
                    customerName={sales.customerName}
                    totalSales = {sales.totalSales}
                    payment = {sales.payment}
                    outstanding = {sales.outstanding}
                    customerAddress = {sales.customerAddress}
                    customerPhoneNo = {sales.customerPhoneNo}
                    note = {sales.note}
                    items = {sales.items}
                    rePrint = {sales.rePrint}
                    
                    ref={componentRef} />

                    <button onClick={handlePrint}>Print this out!</button>
            </div>
            }
            
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ salesPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-6 border-right">
                        <form>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="inv-no" text="No Faktur" isMandatory={ true } value={ sales.invoiceNo } />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        value={ sales.invoiceNo }
                                        disabled />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="cust-name" text="Pelanggan" isMandatory={ true } value={ sales.customerName } />
                                    <Select
                                        id="cust-name"
                                        paramFilter="name"
                                        api={ salesPath.custFind }
                                        entityId="1,2,3"
                                        onChange={ onChangeCustomer }
                                        value={ selectedCustName }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="trx-date" text="Tanggal" isMandatory={ true } value={ sales.trxDate } />
                                    {
                                        tlock === '1' && <input disabled type='text' className='form-control' value={`${formatDate(sales.trxDate, 'LL', constant.LOCALE)}`} />
                                    }
                                    {
                                        tlock === '0' && <DayPicker id="trx-date" name="trxDate" valueDate={ sales.trxDate } onDayChange={ onDayChange } />
                                    }
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="note" text="Catatan" />
                                    <div>
                                        <textarea 
                                            id="note"
                                            className="form-control" 
                                            name="note"
                                            value={sales.note}
                                            onChange={ e => onInputChange(e) } 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="address" text="Alamat" />
                                    <div>
                                        <textarea 
                                            id="address"
                                            className="form-control" 
                                            name="address"
                                            value={sales.customerAddress}
                                            onChange={ e => onInputChange(e) } 
                                        />
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-6">
                        <form onSubmit={ e => onSubmit(e) }>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="total" text="Total" isMandatory={ true } value={ sales.totalSales } />
                                    <InputNumeral 
                                        id="total"
                                        value={ sales.totalSales }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="payment" text="Pembayaran" />
                                    <InputNumeral 
                                        id="payment"
                                        value={ sales.payment }
                                        onValueChange={ onPaymentChange }
                                        isDisabled={ !isRePrint }
                                    />
                                </div>
                            </div>

                            {
                                isRePrint &&
                                <>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <Label htmlFor="outstanding" text="Sisa Pembayaran" />
                                        <InputNumeral 
                                            id="outstanding"
                                            value={ sales.outstanding }
                                            display={ true }
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <Label htmlFor="payment-method" text="Metode Pembayaran" isMandatory={ true } value={ sales.paymentMethodName} />
                                        <Select
                                            id="payment-method"
                                            paramFilter="name"
                                            api={ salesPath.paymentMethodFind }
                                            onChange={ onChangePaymentMethod }
                                            value={ selectedPaymentMethod }
                                        />
                                    </div>
                                </div>
                                </>
                                
                            }

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label />
                                    <ActionButton 
                                        isSave={ false }
                                        isPrint={ isPrint }
                                        onPrint={handlePrint}
                                        isCancel={ false }
                                        isDisabledSave={ isDisabledSave }
                                        isDisabledAdd={ isDisabledAdd }
                                        addLabel="Tambah Detail Pembelian" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <br />
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6"><Note isAction={ true } /></div>
                </div>

                {
                    isRePrint &&
                    <>
                        <div className="line"></div>
                
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Label htmlFor="gns-name" text="Nama Barang" isMandatory={ true } value={ gns.name } />
                                        <Select
                                            id="gns-name"
                                            api={ salesPath.gnsFind }
                                            entityId="1"
                                            value={ selectedGnsName }
                                            onChange={ onGnsNameChange }
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <Label htmlFor="selling-price" text="Harga" isMandatory={ true } value={ gns.sellingPrice } />
                                        <InputNumeral 
                                            id="selling-price"
                                            value={ gns.sellingPrice }
                                            onValueChange={ onSellingPriceChange }
                                        />
                                        <span style={{fontSize: '0.9rem', color: '#6c757d'}}>
                                            Jual:&nbsp;<span style={{ cursor: 'pointer', fontWeight: '900', color: '#28a745' }} onClick={ e => setSellingPrice(e) }><TextNumber value={ gns.sellingPriceOrigin } /></span>
                                            &nbsp;|&nbsp;
                                            Beli:&nbsp;<span style={{ cursor: 'pointer', fontWeight: '900', color: '#dc3545' }} onClick={ e => setPurchasePrice(e) }><TextNumber value={ gns.purchasePrice } /></span>
                                        </span>
                                    </div>

                                    <div className="col-md-2">
                                        <Label htmlFor="qty" text="Qty" isMandatory={ true } value={ gns.qty } />
                                        <InputNumeral 
                                            id="qty"
                                            value={ gns.qty }
                                            onValueChange={ onQtyChange }
                                        />
                                    </div>
                                    
                                </div>

                                <div className="form-group row">

                                    <div className="col-md-4">
                                        <Label text="Satuan" />
                                        <input type="text" className="form-control" value={ gns.unit } disabled />
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <Label text="Stock" />
                                        <InputNumeral 
                                            value={ gns.stock }
                                            display={ true }
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <Label />
                                        <div className="btn-group d-flex">
                                            <ClickButton isDisabled={ isDisabledAdd } className="p-2 flex-fill btn btn-success" label={ idxItem === -1 ? `Tambah` : `Perbaharui` } onClick={ onAddItem } />
                                            {
                                                gns.code &&
                                                <ClickButton onClick={ resetGns } className="p-2 flex-fill btn btn-info" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                <br />

                {
                    sales.items && 
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nama Barang</th>
                                            <th>Satuan</th>
                                            <th>Quantity</th>
                                            <th>Harga Jual</th>
                                            <th>Sub Total</th>
                                            {
                                                isRePrint &&
                                                <th>Detail</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sales.items.map((item, index) => {
                                                return(
                                                    <tr key={ index }>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ item.name }</td>
                                                        <td>{ item.unit }</td>
                                                        <td>{ item.qty }</td>
                                                        <td><TextNumber value={ item.sellingPrice } /></td>
                                                        <td><TextNumber value={ parseFloat(item.qty) * parseFloat(item.sellingPrice) } /></td>
                                                        {
                                                            isRePrint &&
                                                            <td>
                                                                <button className={`btn ${constant.BTN_CLASS_DETAIL}`} onClick={ () => onViewDetail(index) }>{ constant.DETAIL }</button>
                                                            </td>
                                                        }
                                                        
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>
    );
};

export default Action;
