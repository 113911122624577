import React, { useState, useEffect, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { useLocation, useHistory } from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import { useReactToPrint } from 'react-to-print';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash';

import Label from '../../../components/label';
import Select from '../../../components/select';
import InputNumeral from '../../../components/input-numeral';
import DayPicker from '../../../components/day-picker';
import ActionButton from '../../../components/action-button';
import ClickButton from '../../../components/click-button';
import TextNumber from '../../../components/text-number';
import { getData, postData, deleteData, putData } from '../../../helper/request-response';
import { onError, onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import salesPath from './config';
import ComponentToPrint from './print.js';

const Action = () => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const data = useLocation();

    const history = useHistory();

    const refData = useRef(data);

    const [modalIsOpen, setIsOpen] = useState(false);

    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isDisabledAdd, setIsDisabledAdd] = useState(true);

    const [isNew, setIsNew] = useState(true);

    const [isPrint, setIsPrint] = useState(false);

    const [isAbnormalPrice, setIsAbnormalPrice] = useState(false);

    const [idxItem, setIdxItem] = useState(-1);

    const [selectedCust, setSelectedCust] = useState(constant.DEFAULT_SELECTED);

    const [selectedDriver, setSelectedDriver] = useState(constant.DEFAULT_SELECTED);

    const [selectedVehicle, setSelectedVehicle] = useState(constant.DEFAULT_SELECTED);

    const [selectedGns, setSelectedGns] = useState(constant.DEFAULT_SELECTED);

    const [oldId, setOldId] = useState(0);

    const [sales, setSales] = useState({
        id: 0,
        isEdit: false,
        invoiceNo: `PS${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
        trxDate: DateTime.local(),
        lineNo: 0,
        isCommit: 0,
        customerId: 0,
        customerName: "",
        vehicleId: 0,
        vehicleNAme: "",
        employeeId: 0,
        employeeName: "",
        totalKgPurchase: 0,
        totalPurchase: 0,
        totalSales: 0,
        deduction: 0,
        payment: 0,
        apArId: 0,
        sellingType: 0,
        items:[]
    });

    const gnsEntity = {
        id: 0,
        code: '',
        name: '',
        purchasePrice: 0,
        sellingPrice: 0,
        qty: 0,
        stock: 0,
        isSubtrahend: false,
        unitId: 0,
        unit: '',
        businessUnitId: 2,
        coaId: 0,
        coaAccount: '',
        coaDescription: ''
    }

    const [gns, setGns] = useState(gnsEntity);

    const onCustChange = opt => {

        if (opt.id) {

            setSales(sales => ({
                ...sales,
                customerId: opt.id,
                customerName: opt.name,
                apArId: opt.apArId
            }));

            setSelectedCust(cust => ({
                ...cust,
                value: opt.id,
                label: opt.name
            }));
        }

    }

    const onDriverChange = opt => {

        if (opt.id) {

            setSales(sales => ({
                ...sales,
                employeeId: opt.id,
                employeeName: opt.label
            }));

            setSelectedDriver(driver => ({
                ...driver,
                value: opt.id,
                label: opt.label
            }));
        }
    }

    const onVehicleChange = opt => {

        if (opt.id) {

            setSales(sales => ({
                ...sales,
                vehicleId: opt.id,
                vehicleNAme: opt.code
            }));

            setSelectedVehicle(vehicle => ({
                ...vehicle,
                value: opt.id,
                label: opt.code
            }));
        }
    }

    const onQtyChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            qty: parseFloat(value)
        }));

    }

    const onSellingPriceChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            sellingPrice: parseFloat(value)
        }));

    }

    const resetGns = () => {

        setGns(gnsEntity);

        setSelectedGns(gns => ({
            ...gns,
            value: '',
            label: ''
        }));

    }

    const onGnsNameChange = opt => {

        if (opt.id) {

            const getCoa = () => {

                if (sales.employeeId === 10 && sales.vehicleId === 5) {

                    return {
                        coaId: 27,
                        coaAccount: '6002001',
                        coaDescription: 'PENJUALAN TBS AGEN'
                    }
                }
    
                return {
                    coaId: 26,
                    coaAccount: '6001000',
                    coaDescription: 'PENJUALAN TBS PETANI'
                }
            }

            setGns(gns => ({
                ...gns,
                id: opt.id,
                code: opt.code,
                name: opt.name,
                purchasePrice: opt.purchasePrice,
                sellingPrice: opt.sellingPrice,
                stock: opt.stock,
                isSubtrahend: opt.isSubtrahend,
                unitId: opt.unitId,
                unit: opt.unit,
                businessUnitId: opt.businessUnitId,
                coaId: opt.coaId,
                coaAccount: opt.coaAccount,
                coaDescription: opt.coaDescription
            }));

            if (opt.id === 349) {

                const coa = getCoa();

                setGns(gns => ({
                    ...gns,
                    coaId: coa.coaId,
                    coaAccount: coa.coaAccount,
                    coaDescription: coa.coaDescription
                }));

            }

            setSelectedGns(gns => ({
                ...gns,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const calculateTotal = items => {

        let total = 0;

        for(let i = 0; i < items.length; i++) {

            if (items[i].id !== 453) {

                const qty = parseFloat(items[i].qty);
                const sellingPrice = parseFloat(items[i].sellingPrice);
                total += qty * sellingPrice;

            }
        }

        return total;

    };

    const onConfirmItem = async e => {

        e.preventDefault();

        const addPurchasedItem = () => new Promise((resolve, reject) => {

            const getLoadGns = () => new Promise((resolve, reject) => {

                getData(`${salesPath.gns}/453`).then(gns => {
                    resolve (gns);
                });

            });

            let gsItems = sales.items;
            gsItems.push(gns);

            if (sales.vehicleId !== 5) {

                getLoadGns().then(result => {

                    const loadGns = result[0];

                    const loadService = {
                        id: loadGns.id,
                        code: loadGns.code,
                        name: loadGns.name,
                        purchasePrice: loadGns.purchasePrice,
                        sellingPrice: loadGns.sellingPrice,
                        qty: gns.qty,
                        stock: loadGns.stock,
                        isSubtrahend: loadGns.isSubtrahend,
                        unitId: loadGns.unitId,
                        unit: loadGns.unit,
                        businessUnitId: loadGns.businessUnitId,
                        coaId: loadGns.coaId,
                        coaAccount: loadGns.coa,
                        coaDescription: loadGns.coaDesc
                    }

                    gsItems.push(loadService);

                    resolve(gsItems);

                });

            } else {

                resolve(gsItems);
            }

        })

        const updatePurchasedItem = () => {

            let gsItems = sales.items;

            for (let i = 0; i < gsItems.length; i++) {

                if (i === idxItem) {

                    gsItems[i].id = gns.id;
                    gsItems[i].code = gns.code;
                    gsItems[i].name = gns.name;
                    gsItems[i].sellingPrice = gns.sellingPrice;
                    gsItems[i].qty = gns.qty;

                    break;
                } 
            }

            return gsItems;

        }

        let gsItems = [];

        if (idxItem < 0) gsItems = await addPurchasedItem();
        else gsItems = updatePurchasedItem();

        const total = calculateTotal(gsItems);

        setSales(data => ({
            ...data,
            totalSales: total,
            payment: parseFloat(total),
            items: gsItems
        }));

        resetGns();

        setIdxItem(-1);

        setIsOpen(false);

        setIsAbnormalPrice(false);

    }

    const onAddItem = e => {

        e.preventDefault();

        if (gns.sellingPrice > gns.qty) setIsAbnormalPrice(true);

        if (gns.id === 349) setIsOpen(true);
        else onConfirmItem(e);

    }

    const closeModal = (e) => {
        
        e.preventDefault();

        setIsOpen(false);

        setIsAbnormalPrice(false);

    }

    const onDayChange = day => {

        setSales(data => ({ 
            ...data,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    const onViewDetail = (index) => {

        const item = sales.items[index];

        setGns(data => ({
            ...data,
            id: item.id,
            code: item.code,
            name: item.name,
            unit: item.unit,
            unitId: item.unitId,
            stock: item.stock,
            qty: item.qty,
            sellingPrice: item.sellingPrice,
            coaId: item.coaId,
            coaAccount: item.coaAccount,
            coaDescription: item.coaDescription
        }));

        setSelectedGns(gns => ({
            ...gns,
            value: item.id,
            label: item.name
        }));

        setIdxItem(index);
    }

    const onDeductionChange = values => {

        const { value } = values;

        if (sales.id === 0) {

            const gsItems = sales.items;
            const total = calculateTotal(gsItems);

            setSales(data => ({
                ...data,
                payment: parseFloat(total),
                deduction: value
            }));

        } else {

            setSales(data => ({
                ...data,
                deduction: value,
                payment: parseFloat(data.totalSales) - parseFloat(value)
            }));

        }
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const post = async () => {

            const result = await postData(salesPath.base, sales);

            if (!_.isEmpty(result)) {

                onSuccess(`Penjualan ${sales.invoiceNo} berhasil di tambahkan`);

                history.goBack();

            }
        };

        const update = () => {

            const params = { 
                params: { 
                    'invoice-no': sales.invoiceNo,
                    'recordType': 'DELETE'
                } 
            };

            postData(salesPath.salesHistory, params).then(salesHistories => {

                if (!_.isEmpty(salesHistories)) {

                    deleteData(salesPath.base, params).then(deleteSales => {

                        if (!_.isEmpty(deleteSales)) {
    
                            postData(salesPath.base, sales).then(result => {
        
                                const newId = result.id;

                                putData(`${salesPath.base}/${oldId}`, { 'params': { 'new-id': newId } } ).then(result => {

                                    if (!_.isEmpty(result)) {
                                        
                                        onSuccess(`Penjualan ${sales.invoiceNo} berhasil di perbaharui`);

                                        history.goBack();
                                        
                                    } else onError(result);

                                });

        
                            });
        
                        }

                    });

                }

            });

        }

        if (sales.isEdit) update();
        else if (!sales.isCommit) post();
        
    }

    useEffect(() => {

        function init() {

            const state = refData.current.state;

            const getSalesDetail = async (id) => await getData(`${salesPath.base}/${id}`);

            if (state) {

                setIsPrint(true);

                setIsNew(false);
    
                setSales(state);

                setSales(sales => ({
                    ...sales,
                    payment: parseFloat(sales.totalSales) - parseFloat(sales.deduction)
                }))
                
                setSelectedCust(cust => ({
                    ...cust,
                    value: state.customerId,
                    label: state.customerName
                }));

                setSelectedDriver(driver => ({
                    ...driver,
                    value: state.employeeId,
                    label: state.employeeName
                }));

                setSelectedVehicle(vehicle => ({
                    ...vehicle,
                    value: state.vehicleId,
                    label: state.vehicleCode
                }));

                setTitleInfo(`Detail: ${state.invoiceNo}`);

                if (state.employeeId === 10) {

                    setSales(sales => ({
                        ...sales,
                        sellingType: 1
                    }));

                } else {

                    setSales(sales => ({
                        ...sales,
                        sellingType: 2 
                    }));

                }

                if (state.isCommit === 1) setIsNew(false);

                getSalesDetail(state.id)
                    .then(result => {

                        if (result) {

                            if (refData.current.isEdit) {

                                setSales(sales => ({
                                    ...sales,
                                    id: 0
                                }));

                                setOldId(state.id);

                            }

                            setSales(sales => ({
                                ...sales,
                                isEdit: refData.current.isEdit,
                                items: result
                            }));

                        }
                    });
    
            } else {
    
                setTitleInfo("Tambah Penjualan Baru");

                setIsPrint(false);

                setIsNew(true);
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (sales.customerId && sales.employeeId && sales.vehicleId) setIsDisabledSave(false);

        if (gns.id && gns.qty && gns.sellingPrice) setIsDisabledAdd(false);

        else setIsDisabledAdd(true);

    }, [sales.customerId, sales.employeeId, sales.vehicleId, gns.id, gns.qty, gns.sellingPrice]);

    return(
        <React.Fragment>
            { isPrint &&
            <div style={{display:"none"}}>
                <ComponentToPrint 
                    invoiceNo = {sales.invoiceNo}
                    trxDate = {`${formatDate(sales.trxDate, 'LL', constant.LOCALE)}`}
                    custName = {sales.customerName}
                    vehicleNAme = {sales.vehicleNAme}
                    deduction = {sales.deduction}
                    totalSales = {sales.totalSales}
                    payment = {sales.payment}
                    items = {sales.items}
                    
                    ref={componentRef} />

                    <button onClick={handlePrint}>Print this out!</button>
            </div>
            }

            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ salesPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">

                <div className="row">
                    <Modal open={ modalIsOpen } onClose={ closeModal } center>
                        <div className="modal-header">
                            <h5 className="modal-title">KONFIRMASI PENJUALAN { gns.name }</h5>
                        </div>

                        <div className="modal-body">
                            <table>
                                <thead>
                                    <tr className="text-danger">
                                        <td><h6>Total Kg Pembelian</h6></td>
                                        <td>:</td>
                                        <td><h6><TextNumber value ={sales.totalKgPurchase} /></h6></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-success">
                                        <td><h6>Quantity({ gns.unit }) Penjualan</h6></td>
                                        <td>:</td>
                                        <td><h6> <TextNumber value={ gns.qty } /></h6></td>
                                    </tr>
                                    <tr className="text-primary">
                                        <td><h6>Harga Jual</h6></td>
                                        <td>:</td>
                                        <td><h6> <TextNumber value={ gns.sellingPrice } /></h6></td>
                                    </tr>
                                    {
                                        isAbnormalPrice &&
                                        <tr className="text-danger">
                                            <td colSpan={3}><h6>Harga (Rp) Penjualan Lebih Besar Dari Tonase (Kg) Pembelian. Harap Di Cek!</h6></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer">
                            <ClickButton onClick={ onConfirmItem } label="Simpan" className="btn btn-primary" />
                        </div>
                    </Modal>
                </div>

                <div className="row">

                    <div className="col-md-6 border-right">
                        <form>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="invoiceNo" text="No Faktur" />
                                    <input 
                                        id="invoiceNo" 
                                        type="text" 
                                        className="form-control"
                                        value={ sales.invoiceNo }
                                        readOnly
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal" />
                                    <div>
                                        <DayPicker id="trx-date" name="trxDate" valueDate={ sales.trxDate } onDayChange={ onDayChange } />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="pks" text="Pabrik" isMandatory={ true } value={ sales.customerId } />
                                    <Select
                                        id="pks"
                                        paramFilter="name"
                                        api={ salesPath.custFind }
                                        entityId="5"
                                        onChange={ onCustChange }
                                        value={ selectedCust }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="driver" text="Supir" isMandatory={ true } value={ sales.employeeId } />
                                    <Select
                                        id="driver"
                                        api={ salesPath.emplFind }
                                        entityId="5"
                                        onChange={ onDriverChange }
                                        value={ selectedDriver }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="vehicle" text="Kendaraan" isMandatory={ true } value={ sales.vehicleId } />
                                    <Select
                                        id="pks"
                                        api={ salesPath.vehicleFind }
                                        onChange={ onVehicleChange }
                                        value={ selectedVehicle }
                                    />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-6">
                        <form onSubmit={ e => onSubmit(e) }>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label text="Pembelian (Kg)" />
                                    <InputNumeral 
                                        value={ sales.totalKgPurchase }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label text="Pembelian (Rp)" />
                                    <InputNumeral 
                                        value={ sales.totalPurchase }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="total" text="Sub Total Penjualan" />
                                    <InputNumeral 
                                        id="total"
                                        value={ sales.totalSales }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="deduction" text="Pot Lain-lain" />
                                    <InputNumeral 
                                        id="deduction"
                                        value={ sales.deduction }
                                        onValueChange={ onDeductionChange }
                                        display={ !isNew }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="payment" text="Total Penjualan" />
                                    <InputNumeral 
                                        id="payment"
                                        value={ parseFloat(sales.payment).toFixed(2) }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label />
                                    <ActionButton 
                                        isSave={ isNew }
                                        isPrint={ isPrint }
                                        onPrint={handlePrint}
                                        isDisabledSave={ isDisabledSave }
                                        isDisabledAdd={ isDisabledAdd }
                                        addLabel="Tambah Detail Pembelian" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    isPrint &&
                    <>
                        <div className="line"></div>
                
                        <div className="row">
                            <div className="col-md-12">

                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <Label htmlFor="gns-name" text="Nama Barang" isMandatory={ true } value={ gns.id }/>
                                        <Select
                                            id="gns-name"
                                            api={ salesPath.gnsFind }
                                            entityId="3"
                                            value={ selectedGns }
                                            onChange={ onGnsNameChange }
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <Label text="Unit" />
                                        <input type="text" className="form-control" value={ gns.unit } disabled />
                                    </div>

                                    <div className="col-md-2">
                                        <Label htmlFor="qty" text={ gns.unit ? `Qty (${gns.unit})` : 'Qty'} isMandatory={ true } value={ gns.qty } />
                                        <InputNumeral 
                                            id="qty"
                                            value={ gns.qty }
                                            onValueChange={ onQtyChange }
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <Label htmlFor="selling-price" text="Harga (Rp)" isMandatory={ true } value={ gns.sellingPrice } />
                                        <InputNumeral 
                                            id="selling-price"
                                            value={ gns.sellingPrice }
                                            onValueChange={ onSellingPriceChange }
                                        />
                                    </div>
                                    
                                    <div className="col-md-3">
                                        <Label />
                                        <div className="btn-group d-flex">
                                            <ClickButton isDisabled={ isDisabledAdd } className="p-2 flex-fill btn btn-success" label={ idxItem === -1 ? `Tambah` : `Perbaharui` } onClick={ onAddItem } />
                                            {
                                                gns.name &&
                                                <ClickButton onClick={ resetGns } className="p-2 flex-fill btn btn-info" />
                                            }
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </>
                }

                <br />

                {
                    sales.items && 

                    <div className="col-lg-12 mb-4">
                        <div className="row">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nama Barang</th>
                                            <th>Satuan</th>
                                            <th>Quantity</th>
                                            <th>Harga Jual</th>
                                            <th>Sub Total</th>
                                            {
                                                isPrint &&
                                                <th>Detail</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sales.items.map((item, index) => {
                                                return(
                                                    <tr key={ index }>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ item.name }</td>
                                                        <td>{ item.unit }</td>
                                                        <td>{ item.qty }</td>
                                                        <td><NumberFormat value={ item.sellingPrice } displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} /></td>
                                                        <td><NumberFormat value={ parseFloat(item.qty) * parseFloat(item.sellingPrice) } displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} /></td>
                                                        {
                                                            isPrint &&
                                                            <td>
                                                                <button className={`btn ${constant.BTN_CLASS_DETAIL}`} onClick={ () => onViewDetail(index) }>Detail</button>
                                                            </td>
                                                        }
                                                        
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>
    );
};

export default Action;
