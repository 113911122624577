import React, { useState, useEffect, useRef } from 'react';

import LinkButton from '../../../components/link-button';
import { getData } from '../../../helper/request-response';
import addressPath from './config';

const List = (props) => {

    const refProps = useRef(props);

    const [addresses, setAddresses] = useState([]);

    const getDetailPath = (addressId) => {

        const id = props.customerId ? props.customerId : props.employeeId;

        if (props.customerId) return addressPath.custAddrDetailPath.replace(":parentId", id).replace(":id", addressId);
        else return addressPath.emplAddrDetailPath.replace(":parentId", id).replace(":id", addressId);

    };

    useEffect(() => {

        const getAddresses = async () => {

            const prop = refProps.current;

            const result = await getData(addressPath.baseApi, { params: { 'customer-id': prop.customerId, 'employee-id': prop.employeeId }});
    
            return result;
            
        };

        getAddresses()
            .then((data) => {
                setAddresses(data);
            });;

    }, []);

    return(
        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-striped table-hover card-text">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Lokasi</th>
                                <th>Alamat</th>
                                <th>Opsi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                addresses.map((address, index) => {
                                    return(
                                        <tr key={index}>
                                            <th scope="row">{ index + 1 }</th>
                                            <td>{ address.location }</td>
                                            <td>{ address.address }</td>
                                            <td>
                                                <LinkButton to={ {pathname: getDetailPath(address.id), state: {"address": address, "customerId": props.customerId, "employeeId": props.employeeId, "name": props.name}} } />
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default List;
