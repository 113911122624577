import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ApArSumList from './List';
import apArSumPath from './config';

const ApArSumRoutes = () => {
    return (
        <Switch>
            <Route path={apArSumPath.basePath} exact component={ApArSumList} />
        </Switch>
    );
};

export default ApArSumRoutes;