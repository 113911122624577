import React from 'react';
import {Switch, Route} from 'react-router-dom';
import DailySumList from './List';
import dailySumPath from './config';

const DailySumRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={dailySumPath.basePath} exact component={DailySumList} />
            </Switch>
        </React.Fragment>
    );
};

export default DailySumRoutes;
