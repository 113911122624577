import apiPath from '../../../config/api';

const SalesPath = {
    title: "Toko Material - Modul Penjualan",
    basePath: "/material/penjualan",
    newPath: "/material/penjualan/baru",
    detailPath: "/material/penjualan/opsi/:id",
    base: apiPath.retailSales,
    baseHistory: apiPath.retailSalesHistory,
    salesExcel: apiPath.retailSalesExcel,
    custFind: apiPath.masterCustFind,
    gnsFind: apiPath.masterGnsFind,
    paymentMethodFind: apiPath.masterPaymentFind,
}

export default SalesPath;
