import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import addressPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isResetable, setIsResetable] = useState(true);

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const addrEntity = {
        id: 0,
        location: "",
        address: "",
        employeeId: null,
        customerId: null
    }

    const [address, setAddress] = useState(addrEntity);

    const onReset = e => {

        e.preventDefault();

        setAddress(addrEntity);

    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setAddress(addr => ({ 
            ...addr,
            [name]: value
        }));

    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postAddress = async () => {

            const result = await postData(
                addressPath.baseApi, 
                {
                    'location': address.location,
                    'address': address.address,
                    'customerId': data.state.customerId,
                    'employeeId': data.state.employeeId
                }
            );

            if (Object.keys(result).length) {

                onSuccess(`Alamat ${result.location} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putAddress = async () => {

            const result = await putData(`${addressPath.baseApi}/${address.id}`, address);

            if (Object.keys(result).length) {
                
                onSuccess(`Alamat ${result.location} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (address.id === 0 && address.location) postAddress();
        else putAddress();
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (Object.keys(state.address).length) {

                setAddress(state.address);

                setTitleInfo(state.name);

                setIsResetable(false);

            } else {

                setTitleInfo(`Alamat Baru Untuk ${state.name}`);
                
            }
        }

        init();

    }, []);

    useEffect(() => {

        if (address.location) setIsDisabledSave(false);

    }, [address.location]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{addressPath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">

                <div className="row">
                    
                    <div className="col-md-8">
                        <form className="form-horizontal" onSubmit={e => onSubmit(e)}>
                            <div className="form-group row">
                                <Label htmlFor="location" isCol={ true } text="Lokasi" isMandatory={ true } value={ address.location } />
                                <div className="col-md-9">
                                    <input 
                                        id="location"
                                        type="text" 
                                        className="form-control" 
                                        name="location"
                                        value={address.location}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="address" isCol={ true } text="Alamat" />
                                <div className="col-md-9">
                                    <textarea 
                                        id="address"
                                        className="form-control" 
                                        name="address"
                                        value={address.address}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave }
                                        isReset={ isResetable }
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
