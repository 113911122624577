import React, { useState, useEffect } from 'react';
import { useGlobalState } from 'state-pool';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import { getData } from '../../../helper/request-response';
import LoaderType from '../../../components/loader-type';
import settingPath from './config';

const List = () => {

    const [rows, ,] = useGlobalState('rows');

    const [isLoad, setIsLoad] = useState(true);

    const [setting, setSetting] = useState([]);

    const [settingFilter, setSettingFilter] = useState({
        'description': ''
    });

    const getDetailPath = (id) => settingPath.detail.replace(':id', id);

    const onFilterChange = e => {

        const { name, value } = e.target;

        setSettingFilter(filter => ({ 
            ...filter,
            [name]: value
        }));
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            'params': { 
                'description': settingFilter.description,
                'rows': rows
            } 
        };

        getCoa(params);

    }

    const getCoa = async(params) => {

        setIsLoad(true);

        const result = await getData(settingPath.api, params);

        setSetting(result);

        setIsLoad(false);

    }

    const onReset = (e) => {

        e.preventDefault();

        setSettingFilter(filter => ({
            ...filter,
            'description': ''
        }));

        getCoa();

    }

    useEffect(() => { getCoa({ 'params': { 'rows': rows } }) }, [rows]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{settingPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="description" isCol={ true } text="Description" />
                                <div className="col-md-9">
                                    <input 
                                        id="description" 
                                        type="text" 
                                        className="form-control"
                                        name="description"
                                        value={settingFilter.description}
                                        onChange={ e => onFilterChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ settingPath.new } newLabel="Tambah Pengaturan Baru" onClick={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Pengaturan Baru" entity="Setting" />
                    </div>
                </div>

                <br />
                { isLoad && <LoaderType /> }
                {
                    !isLoad &&
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Deskripsi</th>
                                            <th>Key</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    {
                                        setting.length > 0 &&
                                        <tbody>
                                            {
                                                setting.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item.description}</td>
                                                            <td>{item.key}</td>
                                                            <td>{item.value}</td>
                                                            <td><LinkButton to={ {pathname: getDetailPath(item.id), state: item} } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div> 
                }
            </div>
        </React.Fragment>
    );
};

export default List;
