import React from 'react';
import Loader from "react-loader-spinner";

const LoaderType = () => {

    return(
        <div className="row">
            <div className="col-md-12 text-center">
                <Loader
                    type="Bars"
                    color="#4680ff"
                    height={80}
                    width={80}
                    timeout={0}
                />
            </div>
        </div>
    );
}

export default LoaderType;
