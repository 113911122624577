import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import { DateTime } from 'luxon';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import InputNumeral from '../../../components/input-numeral';
import LinkButton from '../../../components/link-button';
import {postData} from '../../../helper/request-response';
import {onSuccess} from '../../../helper/toaster';
import constant from '../../../config/constant';
import cashLoanPath from './config';
import { getPoBalance } from '../../../helper/cash-balance';

const Action = () => {
    
    const data = useLocation();
    
    const history = useHistory();

    const [, , setPoBalance] = useGlobalState('poBalance');

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [isNew, setIsNew] = useState(true);
    
    const [titleInfo, setTitleInfo] = useState("");

    const [selectedCustName, setSelectedCustName] = useState(constant.DEFAULT_SELECTED);

    const [cashLoan, setCashLoan] = useState(
        {
            id: 0,
            apArId: 0,
            trxDate: DateTime.local(),
            invoiceNo: `CL${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
            invoiceLineNo: null,
            description: "",
            amount:0,
            coaAccountNo:"",
            masterCustomerId: 0,
            custName: "",
            savingBalance:"",
            loanBalance:""
        });

    const onInputChange = e => {

        const { name, value } = e.target;
        
        const cashLoanData = {
            ...cashLoan,
            [name]: value
        } 

        setCashLoan(cashLoanData);
        
    }

    const onAmountChange = values => {

        const { value } = values;
        const val = parseFloat(value);

        setCashLoan(data => ({
            ...data,
            amount: val
        }));
    }

    const onDayChange = day => {

        setCashLoan({ 
            ...cashLoan,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }

    const onChangeCustomer = opt => {

        if (opt.id) {

            setCashLoan(trx => ({
                ...trx,
                apArId: opt.apArId,
                masterCustomerId: opt.id,
                custName: opt.name,
                savingBalance: opt.apAmount,
                loanBalance:opt.arAmount
            }));

            setSelectedCustName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onReset = e => {

        e.preventDefault();

        setCashLoan(data => ({ 
            ...data,
                id: 0,
                apArId: 0,
                trxDate: DateTime.local(),
                invoiceNo: `CL${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
                invoiceLineNo: null,
                description: "",
                amount:0,
                coaAccountNo:"",
                masterCustomerId: 0,
                savingBalance:"",
                loanBalance:""
        }));

    }

    const onSubmit = (e) => {

        e.preventDefault();

        const postBankTrx = async () => {

            setIsDisabledSave(true);
            setPoBalance(0);

            const result = await postData(cashLoanPath.baseApi, cashLoan);

            if (!_.isEmpty(result)) {

                onSuccess(`Transaksi ${result.invoice_no} berhasil di tambahkan`);

                history.goBack();

                getPoBalance(0);

            }
        };

        if (cashLoan.id === 0) postBankTrx();

    }

    useEffect(() => {

        function setCashLoanState() {

            if (data.state) {

                setCashLoan(data.state)

                setTitleInfo(`Detail: ${data.state.invoiceNo}`);

                setSelectedCustName(code => ({
                    ...code,
                    value: data.state.custId,
                    label: data.state.custName
                }));

                setIsResetable(false);

                setIsNew(false);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setCashLoanState();

    }, [data]);

    useEffect(() => {

    if (cashLoan.custName && cashLoan.description && (cashLoan.amount > 0) ) setIsDisabledSave(false);

    
    if (!cashLoan.custName || !cashLoan.description || !cashLoan.amount || ( cashLoan.amount <= 0) ) setIsDisabledSave(true);

    }, [cashLoan.custName, cashLoan.description, cashLoan.amount]);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{cashLoanPath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="cashLoan-number" text="No Pembayaran"/>
                                <input 
                                id="cashLoan-number"
                                type="text" 
                                className="form-control" 
                                name="invoiceNo"
                                value={cashLoan.invoiceNo}
                                onChange={ e => onInputChange(e) } 
                                readOnly
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="trx-date" text="Tanggal"/>
                                <div>
                                    <DayPicker id="trx-date" name="trxDate" valueDate={cashLoan.trxDate} onDayChange={onDayChange} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="customer-name" text="Pelanggan" isMandatory={ true } value={ cashLoan.custName } />
                                <Select
                                    id="customer-name"
                                    paramFilter="name"
                                    api={ cashLoanPath.custFind }
                                    entityId="3,4"
                                    onChange={ onChangeCustomer }
                                    value={ selectedCustName }
                                />
                                <span><LinkButton to={ cashLoanPath.newCustomer } className="stretched-link" label="Tambah Pelanggan Baru" /></span>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                    <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                        {isNew &&
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="saving-bal" text="Saldo Tabungan"/>
                                <InputNumeral 
                                    id="saving-bal"
                                    value={ cashLoan.savingBalance }
                                    display={ true }
                                    disabled
                                />
                            </div>
                        </div>
                        }

                        {isNew &&
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="loan-bal" text="Saldo Pinjaman"/>
                                <InputNumeral 
                                    id="loan-bal"
                                    value={ cashLoan.loanBalance }
                                    display={ true }
                                    disabled
                                />
                            </div>
                        </div>
                        }

                        {cashLoan.savingBalance === 0 &&
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="cashLoan-description" text="Deskripsi"  isMandatory={ true } value={ cashLoan.description }/>
                                <input 
                                id="cashLoan-description"
                                type="text" 
                                className="form-control" 
                                name="description"
                                value={cashLoan.description}
                                onChange={ e => onInputChange(e) } 
                                />
                            </div>
                        </div>
                        }
                        {cashLoan.savingBalance === 0 &&
                        <div className="form-group row">
                           
                            <div className="col-md-12">
                            <Label htmlFor="cashLoan-amount" text="Nominal" isMandatory={ true } value={ cashLoan.amount } />
                            <InputNumeral 
                                id="amount"
                                value={ cashLoan.amount }
                                onValueChange={ onAmountChange } 
                            />
                            </div>
                        </div>
                        }
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label />
                                <ActionButton 
                                    isSave={ isNew }
                                    isDisabledSave={ isDisabledSave } 
                                    isReset={ isResetable } 
                                    onReset={ onReset } />
                            </div>
                        </div>

                        <Note isAction={ true } />
                    
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
