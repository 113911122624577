import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import Select from '../../../components/select';
import SelectCreate from '../../../components/select-create';
import InputNumeral from '../../../components/input-numeral';
import ActionButton from '../../../components/action-button';
import LinkButton from '../../../components/link-button';
import { getData, postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import { getSellingPrice } from '../../../helper/util';
import gnsPath from './config';
import constant from '../../../config/constant';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);
    
    const [titleInfo, setTitleInfo] = useState("");
    
    const [businessUnits, setBusinessUnits] = useState([]);

    const [selectedGnsCode, setSelectedGnsCode] = useState(constant.DEFAULT_SELECTED);

    const [selectedGnsName, setSelectedGnsName] = useState(constant.DEFAULT_SELECTED);

    const [selectedUnit, setSelectedUnit] = useState(constant.DEFAULT_SELECTED);
    
    const [gns, setGns] = useState({
        id: 0,
        code: "",
        name: "",
        purchasePrice: 0,
        sellingPrice: 0,
        stock: 0,
        unitId: 0,
        unit: "",
        businessUnitId: 0,
        coaId: 0,
        isSubtrahend: false
    });

    const onReset = e => {

        e.preventDefault();

        setGns(gns => ({ 
            ...gns,
            code: "",
            name: "",
            purchasePrice: 0,
            sellingPrice: 0,
            stock: 0,
            unitId: 0,
            unit: "",
            businessUnitId: 0,
            coaId: 0,
            isSubtrahend: false
        }));

    }

    const setGnsState = opt => {

        setGns(gns => ({ 
            ...gns,
            id: opt.id,
            code: opt.code,
            name: opt.name,
            purchasePrice: opt.purchasePrice,
            sellingPrice: opt.sellingPrice,
            stock: opt.stock,
            isSubtrahend: opt.isSubtrahend,
            unitId: opt.unitId,
            businessUnitId: opt.buId,
            coaId: opt.coaId,
            unit: opt.unit
        }));
    }

    const setSelectedState = opt => {

        setSelectedGnsCode(code => ({
            ...code,
            value: opt.id,
            label: opt.code
        }));

        setSelectedGnsName(name => ({
            ...name,
            value: opt.id,
            label: opt.name
        }));

        setSelectedUnit(unit => ({
            ...unit,
            value: opt.unitId,
            label: opt.unit
        }));

    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setGns(gns => ({ 
            ...gns,
            [name]: value
        }));
    };

    const onGnsCodeChange = opt => {

        if (opt.id) {

            setGnsState(opt);

            setSelectedState(opt);

        } else {

            setGns(gns => ({
                ...gns,
                code: opt.label
            }));

            setSelectedGnsCode(code => ({
                ...code,
                value: 0,
                label: opt.label
            }));

        }
    }

    const onGnsNameChange = opt => {

        if (opt.id) {

            setGnsState(opt);

            setSelectedState(opt);

        } else {

            setGns(gns => ({
                ...gns,
                name: opt.label
            }));

            setSelectedGnsName(code => ({
                ...code,
                value: 0,
                label: opt.label
            }));

        }
    }

    const onUnitChange = opt => {

        if (opt.id) {

            setGns(gns => ({
                ...gns,
                unitId: opt.id,
                unit: opt.code
            }));

            setSelectedUnit(unit => ({
                ...unit,
                value: opt.id,
                label: opt.code
            }));
        }
    }

    const onPurchasePriceChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            purchasePrice: parseFloat(value),
            sellingPrice: getSellingPrice(parseFloat(value))
        }));

    }

    const onSellingPriceChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            sellingPrice: parseFloat(value)
        }));

    }

    const onSubtrahendChange = e => {

        setGns(gns => ({
            ...gns,
            isSubtrahend: gns.isSubtrahend ? false : true
        }));
    }

    const onSubmit = e => {

        e.preventDefault();

        const postGoodsAndService = async () => {

            const result = await postData(gnsPath.baseApi, gns);

            if (Object.keys(result).length) {

                onSuccess(`Barang dan jasa ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putGoodsAndService = async () => {

            const result = await putData(`${gnsPath.baseApi}/${gns.id}`, gns);

            if (Object.keys(result).length) {
                
                onSuccess(`Barang dan jasa ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (gns.id === 0 && gns.code && gns.name && gns.unitId) postGoodsAndService();
        else putGoodsAndService();

    }

    useEffect(() => {

        const getBusinessUnit = async () => {

            const result = await getData(gnsPath.baseBusinessUnitApi);

            setBusinessUnits(result);
            
        }

        const init = () => {

            const state = refData.current.state;

            if (state) {

                setGns(state);

                setSelectedGnsCode(code => ({
                    ...code,
                    value: state.id,
                    label: state.code
                }))

                setSelectedGnsName(name => ({
                    ...name,
                    value: state.id,
                    label: state.name
                }))

                setSelectedUnit(unit => ({
                    ...unit,
                    value: state.unitId,
                    label: state.unit
                }));

                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);

            } else {

                setTitleInfo("Tambah Barang dan Jasa Baru");
                
            }

            getBusinessUnit();
        }

        init();

    }, []);

    useEffect(() => {

        if (gns.code && gns.name && gns.unitId && gns.businessUnitId) setIsDisabledSave(false);
        else setIsDisabledSave(true);

    }, [gns.code, gns.name, gns.unitId, gns.coaId, gns.businessUnitId]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{gnsPath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">

                <div className="row">

                    <div className="col-md-8 border-right">

                        <form className="form-horizontal" onSubmit={e => onSubmit(e)}>

                            <div className="form-group row">
                                <Label htmlFor="bu" isCol={ true } text="Unit Bisnis" />
                                <div className="col-md-9">
                                    <select id="bu" className="form-control" name="businessUnitId" onChange={ e => onInputChange(e) } value={ gns.businessUnitId }>
                                        <option value="">Pilih</option>
                                        {businessUnits.map(({id, name}) => {return(<option key={id} value={id}>{name}</option>)})}
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="code" isCol={ true } text="Kode" isMandatory={ true } value={ gns.code } />
                                <div className="col-md-9">
                                    <SelectCreate 
                                        id="code"
                                        api={ gnsPath.gnsFind }
                                        value={ selectedGnsCode }
                                        onChange={ onGnsCodeChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="name" isCol={ true } text="Nama" isMandatory={ true } value={ gns.name } />
                                <div className="col-md-9">
                                    <SelectCreate 
                                        id="name"
                                        api={ gnsPath.gnsFind }
                                        value={ selectedGnsName }
                                        onChange={ onGnsNameChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="unit" isCol={ true } text="Satuan" isMandatory={ true } value={ gns.unitId } />
                                <div className="col-md-9">
                                    <Select 
                                        id="unit"
                                        api={ gnsPath.unitFilter }
                                        value={ selectedUnit }
                                        onChange={ onUnitChange }
                                    />
                                    <span><LinkButton to={ gnsPath.newUnit } className="stretched-link" label="Tambah Satuan Baru" /></span>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="purchase-price" isCol={ true } text="Harga Beli" />
                                <div className="col-md-9">
                                    <InputNumeral
                                        id="purchase-price"
                                        value={ gns.purchasePrice }
                                        onValueChange={ onPurchasePriceChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="selling-price" isCol={ true } text="Harga Jual" />
                                <div className="col-md-9">
                                    <InputNumeral
                                        id="selling-price"
                                        value={ gns.sellingPrice }
                                        onValueChange={ onSellingPriceChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Komponen Pengurang" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="is-subtrahend"
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isSubtrahend"
                                            value={ gns.isSubtrahend }
                                            checked={ gns.isSubtrahend }
                                            onChange={ e => onSubtrahendChange(e) }
                                        />   
                                        <label htmlFor="is-subtrahend" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave }
                                        isReset={ isResetable }
                                        addLabel="Tambah Detail Pembelian"
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
