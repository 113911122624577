import React, { useState, useEffect } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import { useGlobalState } from 'state-pool';

import Label from '../../../components/label';
import TextNumber from '../../../components/text-number';
import ReportButton from '../../../components/report-button';
import DayPicker from '../../../components/day-picker';
import Select from '../../../components/select';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import purchaseSalesDetailPath from './config';
import LoaderType from '../../../components/loader-type';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [materialBuId, ,] = useGlobalState('materialBuId');

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [reset, setReset] = useState();

    const [selectedGns, setSelectedGns] = useState(constant.DEFAULT_SELECTED);

    const [gnsId, setGnsId] = useState();

    const [purchaseSalesDetail, setPurchaseSalesDetail] = useState([]);

    const onEndDateChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const onStartDateChange = day => {

        setStartDate(formatDate(day, constant.DEFAULT_DATE));

        setEndDate(formatDate(day, constant.DEFAULT_DATE));
    
    };

    const getPurchaseSalesDetail = () => {

        const params = getParams();

        setIsLoad(true);

        getData(purchaseSalesDetailPath.base, params).then(result => {
            
            setPurchaseSalesDetail(result[0]);

            setIsLoad(false);
        
        });
    }

    const getParams = () => {

        const params = {
            params: { 
                'start-date': DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                'end-date': DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                'gns-id': gnsId,
                'bu-id': materialBuId
            }
        };

        return params;
    }

    const onNameChange = opt => {

        if (opt.id) {
            
            setSelectedGns(name => ({
                ...name,
                value: opt.id,
                label: opt.label
            }));

            setGnsId(opt.id);

        }

    }
    
    const onExport = e => {

        e.preventDefault();

        const params = getParams();

        getFile(purchaseSalesDetailPath.purchaseSalesDetailExcel, params).then(response => downloadFile(response));

    }

    const onSubmit = e => {

        e.preventDefault();

        getPurchaseSalesDetail();

    }

    const onReset = e => {

        e.preventDefault();

        setIsLoad(true);

        setStartDate(DateTime.local());

        setEndDate(DateTime.local());

        setSelectedGns(constant.DEFAULT_SELECTED);

        setGnsId();

        setReset('reset');
    }

    useEffect(getPurchaseSalesDetail, [reset]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ purchaseSalesDetailPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="start-date" text="Tanggal Dari" />
                                    <DayPicker id="start-date" name="start-date" onDayChange={ onStartDateChange } valueDate={ startDate } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="end-date" text="Tanggal Ke" />
                                    <DayPicker id="end-date" name="end-date" onDayChange={ onEndDateChange } valueDate={ endDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                
                                <div className="col-md-12">
                                    <Label htmlFor="gns-name" text="Nama Barang" />
                                    <Select
                                        id="gns-name"
                                        api={ purchaseSalesDetailPath.purchaseSalesDetailFind }
                                        entityId={materialBuId}
                                        onChange={ onNameChange }
                                        value={ selectedGns }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    isLoad &&
                    <LoaderType />
                }
                
                {
                    !isLoad && purchaseSalesDetail.length > 0 &&
                    <>
                        <br />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Tanggal</th>
                                                <th>Kode Barang</th>
                                                <th>Nama Barang</th>
                                                <th>Type</th>
                                                <th>No Invoice</th>
                                                <th>Jumlah</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                purchaseSalesDetail.map((psd, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <td>{ index + 1 }</td>
                                                            <td>{ `${formatDate(psd.trx_date, 'LL', constant.LOCALE)}` }</td>
                                                            <td>{ psd.code }</td>
                                                            <td>{ psd.name }</td>
                                                            <td>{ psd.trx_type }</td>
                                                            <td>{ psd.invoice_no }</td>
                                                            <td><TextNumber value={ psd.qty } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
