import React, { useEffect, useState } from 'react';

const Label = (props) => {

    const className = 'form-control-label';

    const [col, setCol] = useState('col-md-3');

    useEffect(() => {

        if (props.col) setCol(props.col);

    }, [props.col]);

    if (props.isMandatory && !props.value) {

        return(
            <label htmlFor={ props.htmlFor ? props.htmlFor : "" } className={ props.isCol ? `${col} ${className}` : `${className}` }>
                {
                    props.text &&
                    <span className="text-danger font-weight-bold">{ props.text }<sup>*</sup></span>
                }
                {
                    !props.text &&
                    <span className="text-danger font-weight-bold">&nbsp;</span>    
                }
            </label>
        );

    } else {

        return(
            <label htmlFor={ props.htmlFor ? props.htmlFor : "" } className={ props.isCol ? `${col} ${className}` : `${className}` }>
                { props.text ? props.text : "\u00A0" }
            </label>
        );

    }
}

export default Label;
