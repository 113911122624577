import apiPath from '../../../config/api';

const ReceivablePath = {
    title: "Penagihan Piutang Pelanggan",
    basePath: "/akunting/piutang",
    newPath: "/akunting/piutang/baru",
    detailPath: "/akunting/piutang/opsi/:id",
    baseApi: apiPath.accountReceivable,
    custFind: apiPath.accountReceivableCustomer,
    paymentFind: apiPath.masterPaymentFind
}

export default ReceivablePath;
