import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import { Modal } from 'react-responsive-modal';
import ClickButton from '../../../components/click-button';
import _ from 'lodash';

import Label from '../../../components/label';
import ActionButton from '../../../components/action-button';
import DayPicker from '../../../components/day-picker';
import { getData, postData, deleteData } from '../../../helper/request-response';
import { getDateApiParam, getDateFromJs, getISODate, getDisplayDateFromISO } from '../../../helper/util';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import stockPath from './config';

const Action = () => {

    const [isOpen, setIsOpen] = useState(false);

    const [generated, setGenerated] = useState([]);

    const [maxDate, setMaxDate] = useState(undefined);

    const [minDate, setMinDate] = useState(undefined);

    const [periode, setPeriode] = useState(undefined);

    const [maxPeriode, setMaxPeriode] = useState(undefined);

    const onPeriodeChange = day => {

        setPeriode(formatDate(day, constant.DEFAULT_DATE));

    };

    const onDelete = e => {

        e.preventDefault();

        setIsOpen(true);

    }

    const onConfirmDelete = async(e) => {

        e.preventDefault();

        const params = {
            'params': {
                'periode': getDateApiParam(DateTime.fromJSDate(minDate).toString(constant.DEFAULT_DATE))
            }
        }

        const result = await deleteData(stockPath.api, params);

        if (!_.isEmpty(result)) {

            generatedStock();
            setIsOpen(false);
            
        }

    }

    const onCloseModal = () => setIsOpen(false);

    const generatedStock = async () => {

        const result = await getData(stockPath.generated);
        const maxPrd = new Date(Math.max.apply(null, result.map(function(e) {
            return DateTime.fromISO(e.periode).toJSDate();
        })));

        setMaxPeriode(maxPrd);

        setGenerated(result);
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const post = async() => {

            const param = {
                'periode': getDateApiParam(DateTime.fromISO(periode).toString(constant.DEFAULT_DATE)),
                'source-date': getDateApiParam(DateTime.fromISO(periode).minus({month: 1}).toString(constant.DEFAULT_DATE))
            }

            const result = await postData(stockPath.api, param);

            if (!_.isEmpty(result)) {

                onSuccess(`Stok Awal ${periode} berhasil di tambahkan`);

                generatedStock();

            }
        }

        post();
        
    }

    useEffect(() => {

        const now = DateTime.local();
        const currMonth = now.month;
        const currYear = now.year;
        const minDt = DateTime.fromObject({year: currYear, month: currMonth, day: 1});
        const maxDt = minDt.plus({ month: 1 });

        setMinDate(minDt.toJSDate());
        setMaxDate(maxDt.toJSDate());
        setPeriode(minDt.toJSDate());

        generatedStock();

    }, []);

    useEffect(() => {

        if (minDate === maxPeriode) setPeriode(formatDate(maxDate, constant.DEFAULT_DATE));

    }, [minDate, maxDate, maxPeriode]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ stockPath.title } | Saldo Stok Awal</h3>
            </div>
            <div className="card-body">
                <div className="row">
                <Modal open={ isOpen } onClose={ onCloseModal } center>
                        <div className="modal-header">
                            <h5 className="modal-title">KONFIRMASI PENGHAPUSAN DATA</h5>
                        </div>

                        <div className="modal-body">
                            <table className="table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Hapus Stock Awal Periode</td>
                                        <td>:</td>
                                        <td>{ DateTime.fromJSDate(minDate).toFormat(constant.DISPLAY_DATE) }</td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer">
                            <ClickButton onClick={ onConfirmDelete } label="Hapus" className="btn btn-primary" />
                        </div>
                    </Modal>
                </div>
                <div className="row">

                    <div className="col-md-6 border-right">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-hover card-text">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Periode</th>
                                                    <th>Opsi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    generated.map((item, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <th scope="row">{ index + 1 }</th>
                                                                <td>{ getDisplayDateFromISO(item.periode) }</td>
                                                                <td>
                                                                    { getDateFromJs(minDate) !== getISODate(item.periode) && 'Periode Terkunci' }
                                                                    { 
                                                                        getDateFromJs(minDate) === getISODate(item.periode) && 
                                                                        <ClickButton onClick={ onDelete } label="Hapus" className="btn btn-danger" value={ index } />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <form onSubmit={ e => onSubmit(e)}>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="generate-date" text="Tanggal" />
                                    <DayPicker id="generate-date" name="periode" onDayChange={ onPeriodeChange } valueDate={ periode } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label />
                                    <ActionButton 
                                        isSave={ true }
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default Action;
