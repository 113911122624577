import React, { useState, useEffect } from 'react';

import Label from '../../../components/label';
import Note from '../../../components/note';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import LoaderType from '../../../components/loader-type';
import { getData } from '../../../helper/request-response';
import customerPath from './config';

const List = () => {

    const [customers, setCustomers] = useState([]);

    const [customertype, setCustomerType] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    const [customerFilter, setFilterCustomer] = useState({
        name: "",
        masterCustomerTypeId: ""
    });

    const getDetailPath = (id) => customerPath.detailPath.replace(":id", id);

    const onInputChange = e => {

        setFilterCustomer({ 
            ...customerFilter,
            [e.target.name]: e.target.value
        });
    };

    const onSubmit = e => {

        e.preventDefault();

        setIsLoad(true);

        const params = { 
            params: { 
                name: customerFilter.name, 
                masterCustomerTypeId: customerFilter.masterCustomerTypeId 
            }
        };

        getCustomers(params);

    };

    const getCustomers = async(params) => {

        const result = await getData(customerPath.baseApi, params);
            
        setCustomers(result);

        setIsLoad(false);

    }

    const onReset = e => {

        e.preventDefault();

        setIsLoad(true);

        setFilterCustomer({ 
            ...customerFilter,
            name: "",
            masterCustomerTypeId: 0
        });

        getCustomers();

    }

    const getCustomerType = async() => {

        const result = await getData(customerPath.baseApiCustomerType);

        setCustomerType(result);

    }

    useEffect(() => { 

        getCustomers();
        
        getCustomerType();

    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{customerPath.title}</h3>
            </div>
            <div className="card-body">
                {
                    isLoad && 
                    <LoaderType />
                }

                {
                    !isLoad &&
                    <>
                        <div className="row">
                            <div className="col-md-8 border-right">
                                <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                                    <div className="form-group row">
                                        <Label htmlFor="customer-code" isCol={ true } text="Kode Pelanggan" />
                                        <div className="col-md-9">
                                            <input 
                                                id="customer-code" 
                                                type="text" 
                                                className="form-control"
                                                name="code"
                                                value={customerFilter.code}
                                                onChange={ e => onInputChange(e) } />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <Label htmlFor="customer-name" isCol={ true } text="Nama Pelanggan" />
                                        <div className="col-md-9">
                                            <input 
                                                id="customer-name" 
                                                type="text" 
                                                className="form-control"
                                                name="name"
                                                value={customerFilter.name}
                                                onChange={ e => onInputChange(e) } />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <Label htmlFor="customer-type" isCol={ true } text="Tipe Pelanggan" />
                                        <div className="col-md-9">
                                            <select id="customer-type" className="form-control" name="masterCustomerTypeId" onChange={ e => onInputChange(e) } value={customerFilter.masterCustomerTypeId}>
                                                <option value="">Pilih</option>
                                                {customertype.map(({id, name}) => {return(<option key={id} value={id}>{name}</option>)})}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <Label isCol={ true } />
                                        <div className="col-md-9">
                                            <FilterButton to={ customerPath.newPath } newLabel="Tambah Pelanggan Baru" onClick={ onReset } />
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-md-4">
                                <Note isFilter={ true } label="Tambah Pelanggan Baru" entity="Pelanggan" />
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="col-lg-12 mb-4">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Kode Pelanggan</th>
                                                <th>Nama Pelanggan</th>
                                                <th>No Telepon</th>
                                                <th>Tipe Pelanggan</th>
                                                <th>Opsi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                customers.map((customer, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ customer.code }</td>
                                                            <td>{ customer.name }</td>
                                                            <td>{ customer.phoneNo }</td>
                                                            <td>{ customer.type }</td>
                                                            <td><LinkButton to={ {pathname: getDetailPath(customer.id), state: customer} } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
