import apiPath from '../../../config/api';

const SettingPath = {
    'title': 'Setting', 
    'base': '/admin/setting',
    'new': '/admin/setting/baru',
    'detail': '/admin/setting/opsi/:id',
    'api': apiPath.setting
}

export default SettingPath;
