import apiPath from '../../../config/api';

const ApArSumPath = {
    title: "Laporan Hutang Piutang",
    basePath: "/akunting/laporan/hutang-piutang",
    baseApi: apiPath.accountApArSum,
    accountApArSumExcel : apiPath.accountApArSumExcel
}

export default ApArSumPath;
