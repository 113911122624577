import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SettingList from './List';
import SettingAction from './Action';
import settingPath from './config';

const CoaRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ settingPath.base } exact component={ SettingList } />
                <Route path={ settingPath.new } exact component={ SettingAction } />
                <Route path={ settingPath.detail } exact component={ SettingAction } />
            </Switch>
        </React.Fragment>
    );
};

export default CoaRoutes;
