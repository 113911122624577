import React from "react";

const ComponentToPrint = React.forwardRef((props, ref) => {
    
    return (
      <div ref={ref}>
        <p className="centered">BUKTI PEMBAYARAN PEMBELIAN TBS</p>
        <p className="centered">KOPERASI BUMI AYU LESTARI</p>
        <p className="centered" >RANTAU SAKTI - TAMBUSAI UTARA - RIAU</p>

        <table style={{width:"500px"}}>
          <tr>
            <td>NO</td>
            <td>&nbsp;:&nbsp;</td>
            <td>{props.invoiceNo}</td>
          </tr>
          <tr>
            <td>TANGGAL</td>
            <td>&nbsp;:&nbsp;</td>
            <td>{props.trxDate}</td>
          </tr>
          <tr>
            <td>N A M A</td>
            <td>&nbsp;:&nbsp;</td>
            <td>{props.custName}</td>
          </tr>
          <tr>
            <td>TYPE</td>
            <td>&nbsp;:&nbsp;</td>
            <td>{props.custTypeName}</td>
          </tr>
          <tr>
            <td>JALUR</td>
            <td>&nbsp;:&nbsp;</td>
            <td>{props.retribution.replace("BIAYA RETRIBUSI - ","")}</td>
          </tr>
          <tr>
            <td>KENDARAAN</td>
            <td>&nbsp;:&nbsp;</td>
            <td>{props.vehicleCode}</td>
          </tr>
        </table>

        <table style={{width:"1000px"}}>
          <tr>
              <th colSpan="3" style={{width:"500px"}}>TRANSAKSI</th>
              <th colSpan="3" style={{width:"500px"}}>KETERANGAN</th>
          </tr>
          <tr>
              <td>NAMA BARANG</td>
              <td>:</td>
              <td>{props.itemsTbs.name}
              </td>

              <td>SISA PINJAMAN YG LALU</td>
              <td>:</td>
              <td>{props.loanBalance.toLocaleString()}</td>
          </tr>

          <tr>
              <td>QTY</td>
              <td>:</td>
              <td>{(props.itemsTbs.qty ? parseFloat(props.itemsTbs.qty).toLocaleString() : 0)}</td>

              <td>CICILAN PINJAMAN</td>
              <td>:</td>
              <td>{props.loanPayment.toLocaleString()}</td>
          </tr>

          <tr>
              <td>HARGA</td>
              <td>:</td>
              <td>{(props.itemsTbs.purchasePrice ? parseFloat(props.itemsTbs.purchasePrice).toLocaleString() : 0)}</td>

              <td>SISA PINJAMAN SAAT INI</td>
              <td>:</td>
              <td>{(props.loanBalance - props.loanPayment).toLocaleString()}</td>
          </tr>

          <tr>
              <td>JUMLAH</td>
              <td>:</td>
              <td>{(props.itemsTbs.qty ? (props.itemsTbs.isSubtrahend === 1 ? (parseFloat(props.itemsTbs.qty) * parseFloat(props.itemsTbs.purchasePrice)) * -1 : parseFloat(props.itemsTbs.qty) * parseFloat(props.itemsTbs.purchasePrice) ).toLocaleString() : 0) }</td>
          </tr>

          <tr>
              <td>BIAYA RETRIBUSI {props.itemsRetr.purchasePrice} / {props.itemsRetr.unit}</td>
              <td>:</td>
              <td>{(props.itemsRetr.qty ? (props.itemsRetr.isSubtrahend === 1 ? (parseFloat(props.itemsRetr.qty) * parseFloat(props.itemsRetr.purchasePrice)) * -1 : parseFloat(props.itemsRetr.qty) * parseFloat(props.itemsRetr.purchasePrice) ).toLocaleString() : 0) }</td>

              <td>DITABUNGKAN</td>
              <td>:</td>
              <td>{props.paymentToSave.toLocaleString()}</td>
          </tr>

          <tr>
              <td>BIAYA PANEN {props.itemsHarvest.purchasePrice} / {props.itemsHarvest.unit}</td>
              <td>:</td>
              <td>{(props.itemsHarvest.qty ? (props.itemsHarvest.isSubtrahend === 1 ? (parseFloat(props.itemsHarvest.qty) * parseFloat(props.itemsHarvest.purchasePrice)) * -1 : parseFloat(props.itemsHarvest.qty) * parseFloat(props.itemsHarvest.purchasePrice) ).toLocaleString() : 0)}</td>

              <td>TOTAL TABUNGAN YANG LALU</td>
              <td>:</td>
              <td>{(props.savingBalance - props.paymentToSave).toLocaleString()}</td>
          </tr>

          <tr>
              <td>SUB TOTAL</td>
              <td>:</td>
              <td>{props.totalPurchase.toLocaleString()}</td>

              <td>TOTAL TABUNGAN SAAT INI</td>
              <td>:</td>
              <td>{props.savingBalance.toLocaleString()}</td>
          </tr>

          <tr>
              <td>POTONGAN</td>
              <td>:</td>
              <td></td>
          </tr>

          <tr>
              <td>CICILAN PINJAMAN</td>
              <td>:</td>
              <td>{props.loanPayment.toLocaleString()}</td>
          </tr>

          <tr>
              <td>DITABUNGKAN</td>
              <td>:</td>
              <td>{props.paymentToSave.toLocaleString()}</td>
          </tr>

          <tr>
              <td>PEMBULATAN</td>
              <td>:</td>
              <td></td>
          </tr>
        </table>
        <br/>
        <table>
          <tr>
            <td colspan="2">TOTAL UANG YANG DITERIMA</td>
            <td>:</td>
            <td>{props.payment.toLocaleString()}</td>
          </tr>
        </table>
        <br/>

        <table style={{width:"1000px"}}>
          <tr>
            <td style={{textAlign:"center"}}>DIKETAHUI OLEH</td>
            <td style={{textAlign:"center"}}>DITERIMA OLEH</td>
            <td style={{textAlign:"center"}}>DIBAYARKAN OLEH</td>
          </tr>
          <tr>&nbsp;</tr>
          <tr>&nbsp;</tr>
          <tr>&nbsp;</tr>
          <tr>&nbsp;</tr>
          <tr>
            <td style={{textAlign:"center"}}>_______________</td>
            <td style={{textAlign:"center"}}>_______________</td>
            <td style={{textAlign:"center"}}>_______________</td>
          </tr>
          <tr>
            <td style={{textAlign:"center"}}>KETUA KOPERASI</td>
            <td style={{textAlign:"center"}}></td>
            <td style={{textAlign:"center"}}>BENDAHARA</td>
          </tr>
        </table>

      </div>
      
    );
})

export default ComponentToPrint;