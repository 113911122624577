import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DateTime, Settings } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import { Modal } from 'react-responsive-modal';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import ClickButton from '../../../components/click-button';
import Label from '../../../components/label';
import Note from '../../../components/note';
import SelectCreate from '../../../components/select-create';
import DayPicker from '../../../components/day-picker';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import TextNumber from '../../../components/text-number';
import LoaderType from '../../../components/loader-type';
import { getData, getFile, postData, deleteData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import salesPath from './config';

const List = () => {

    Settings.defaultLocale = 'id';

    const [rows, ,] = useGlobalState('rows');

    const [user, ,] = useGlobalState('user');

    const [deleteRoleId, ,] = useGlobalState('deleteRoleId');

    const rowsRef = useRef(rows);

    const [isDelete, setIsDelete] = useState(false);

    const [isLoad, setIsLoad] = useState(true);

    const [modalIsOpen, setIsOpen] = useState(false);

    const [salesItem, setSalesItem] = useState({});

    const [sales, setSales] = useState([]);

    const [selectedCustName, setSelectedCustName] = useState(constant.DEFAULT_SELECTED);

    const salesFilterEntity = {

        'invoiceNo': '',
        'trxDate': undefined,
        'trxDateFrom': undefined,
        'customerId': 0,
        'customerName': "",
        'settled': "",
        'rows': rows

    }

    const [salesFilter, setSalesFilter] = useState(salesFilterEntity);

    const getFilter = useCallback((filter) => {

        const params = { 
            'params': { 
                'invoice-no': _.get(filter, 'invoiceNo'), 
                'trx-date': _.get(filter, 'trxDate'),
                'trx-date-from': _.get(filter, 'trxDateFrom'),
                'customer-id': _.get(filter, 'customerId'),
                'customer-name': _.get(filter, 'customerName'),
                'rows': _.get(filter, 'rows'),
                'inv-prefix': _.get(filter, 'inv-prefix')
            } 
        };

        return params;

    }, []);

    const onCloseModal = () => setIsOpen(false);

    const getDetailPath = (id) => salesPath.detailPath.replace(":id", id);

    const onInputChange = e => {

        const { name, value } = e.target;

        setSalesFilter(filter => ({ 
            ...filter,
            [name]: value
        }));
    };

    const onCustomerChange = (opt) => {

        if (opt.id) {

            setSalesFilter(filter => ({
                ...filter,
                customerId: opt.id,
                customerName: opt.name
            }));

            setSelectedCustName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));

        } else {

            setSalesFilter(filter => ({
                ...filter,
                customerName: opt.label
            }));

            setSelectedCustName(name => ({
                ...name,
                value: '',
                label: opt.label
            }));

        }
    }

    const onDayChange = day => {

        setSalesFilter({ 
            ...salesFilter,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    };

    const onDayFromChange = day => {

        setSalesFilter({ 
            ...salesFilter,
            trxDateFrom: formatDate(day, constant.DEFAULT_DATE),
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    };

    const getParams = () => {

        const params = { 
            params: { 
                'invoice-no': salesFilter.invoiceNo, 
                'trx-date': salesFilter.trxDate,
                'trx-date-from': salesFilter.trxDateFrom, 
                'customer-id': salesFilter.customerId,
                'customer-name': salesFilter.customerName,
                'inv-prefix':'MS'
            } 
        };

        return params;
    }

    const onExport = e =>  {

        e.preventDefault();

        const params = getParams();

        getFile(salesPath.salesExcel, params).then(response => downloadFile(response));

    }

    const onSubmit = e => {

        e.preventDefault();

        setIsLoad(true);

        const params = getFilter({ 'invoiceNo': salesFilter.invoiceNo, 'trxDate': salesFilter.trxDate, 'trxDateFrom': salesFilter.trxDateFrom, 'customerId': salesFilter.customerId, 'customerName': salesFilter.customerName, 'rows': salesFilter.rows, 'inv-prefix':'MS' });

        getSales(params);

    }

    const getSales = params => {

        setIsLoad(true);

        getData(salesPath.base, params).then(result => {

            setSales(result);
            setIsLoad(false);

        });

    }

    const onReset = (e) => {

        e.preventDefault();

        setIsLoad(true);

        setSalesFilter(salesFilterEntity);

        setSelectedCustName(constant.DEFAULT_SELECTED);

        const params = getFilter({ 'rows': salesFilter.rows, 'inv-prefix':'MS' });

        getSales(params);

    }

    const onDelete = e => {

        e.preventDefault();

        const val = parseInt(e.currentTarget.value);

        setSalesItem(sales[val]);

        setIsOpen(true);

    }

    const onConfirmDelete = async(e) => {

        e.preventDefault();

        setIsLoad(true);

        let param = salesItem;
        param.recordType = 'DELETE';

        const historyResult = await postData(salesPath.baseHistory, param);

        if (historyResult) {

            const params = {
                params: { 
                    'invoice-no': salesItem.invoiceNo,
                    'sales-invoice-no': salesItem.salesInvoiceNo
                }
            };
            
            const removeResult = await deleteData(salesPath.base, params);

            if (!_.isEmpty(removeResult)) {

                onSuccess(`Pembelian ${salesItem.invoiceNo} berhasil di hapus`);

                const params = getFilter({ 'rows': rowsRef.current, 'inv-prefix':'MS'});
        
                getSales(params);

                setIsOpen(false);
            }
        }

    }

    useEffect(() => { 

        const params = getFilter({ 'rows': rowsRef.current, 'inv-prefix':'MS'});

        getSales(params);

        if (_.findIndex(deleteRoleId, f => f === user.roleId.toString()) !== undefined && _.findIndex(deleteRoleId, f => f === user.roleId.toString()) > -1) {
            setIsDelete(true);
        }
    
    }, [getFilter, user.roleId, deleteRoleId]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ salesPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <Modal open={ modalIsOpen } onClose={ onCloseModal } center>
                        <div className="modal-header">
                            <h5 className="modal-title">KONFIRMASI PENGHAPUSAN DATA</h5>
                        </div>

                        <div className="modal-body">
                            <table className="table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Faktur Penjualan</td>
                                        <td>:</td>
                                        <td>{salesItem.invoiceNo}</td>
                                    </tr>
                                    <tr>
                                        <td>Tanggal Transaksi</td>
                                        <td>:</td>
                                        <td>{`${formatDate(salesItem.trxDate, 'LL', constant.LOCALE)}`}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Pelanggan</td>
                                        <td>:</td>
                                        <td>{salesItem.customerName}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer">
                            <ClickButton onClick={ onConfirmDelete } label="Hapus" className="btn btn-primary" />
                        </div>
                    </Modal>
                </div>
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="inv-no" text="No Faktur" />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        name="invoiceNo"
                                        value={ salesFilter.invoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="cust-name" text="Pelanggan" />
                                    <SelectCreate
                                        id="cust-name"
                                        paramFilter="name"
                                        name="customerName"
                                        api={ salesPath.custFind }
                                        entityId="1,2,3"
                                        onChange={ onCustomerChange }
                                        value={ selectedCustName }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Penjualan Dari" />
                                    <DayPicker id="trx-date" name="trxDateFrom" onDayChange={ onDayFromChange } valueDate={ salesFilter.trxDateFrom } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Penjualan Ke" />
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } valueDate={ salesFilter.trxDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ salesPath.newPath } newLabel="Tambah Data Penjualan" onClick={ onReset } isExport={ true } onExport={ onExport } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Penjualan" entity="Penjualan" />
                    </div>
                </div>

                {
                    isLoad &&
                    <LoaderType />
                }

                {
                    !isLoad && sales.length > 0 &&
                    <>
                        <br />
                                
                        <div className="col-lg-12 mb-4">
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>No Faktur</th>
                                                <th>Tanggal</th>
                                                <th>Nama Pelanggan</th>
                                                <th>Total Penjualan (Rp)</th>
                                                <th>Total Pembayaran (Rp)</th>
                                                <th>Status</th>
                                                <th>Opsi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sales.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ item.invoiceNo }</td>
                                                            <td>{ DateTime.fromISO(item.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                            <td>{ item.customerName }</td>
                                                            <td><TextNumber value={ item.totalSales } /></td>
                                                            <td><TextNumber value={ item.payment } /></td>
                                                            <td>{ item.isCommit ? constant.COMMIT : constant.VOID }</td>
                                                            {
                                                                isDelete &&
                                                                <td className="btn-group">
                                                                    <LinkButton to={ {pathname: getDetailPath(item.id), state: item} } />
                                                                    <ClickButton onClick={ onDelete } label="Hapus" className="btn btn-danger" value={ index } />
                                                                </td>
                                                            }
                                                            {
                                                                !isDelete && <td><LinkButton to={ {pathname: getDetailPath(item.id), state: item} } /></td>
                                                            }
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
