import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import { Modal } from 'react-responsive-modal';
import { useGlobalState } from 'state-pool';
import { useReactToPrint } from 'react-to-print';
import _ from 'lodash';

import Label from '../../../components/label';
import Select from '../../../components/select';
import SelectCreate from '../../../components/select-create';
import Note from '../../../components/note';
import DayPicker from '../../../components/day-picker';
import ActionButton from '../../../components/action-button';
import ClickButton from '../../../components/click-button';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import LinkButton from '../../../components/link-button';
import { getData, postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import { getSellingPrice } from '../../../helper/util';
import constant from '../../../config/constant';
import purchasePath from './config';
import ComponentToPrint from './print.js';

const Action = () => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const data = useLocation();
    const refData = useRef(data);
    const history = useHistory();

    const [, , setRetailBalance] = useGlobalState('retailBalance');

    const [tlock, ,] = useGlobalState('tlock');

    const [titleInfo, setTitleInfo] = useState("");

    const [modalIsOpen, setIsOpen] = useState(false);

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isDisabledAdd, setIsDisabledAdd] = useState(true);

    const [isNew, setIsNew] = useState(true);

    const [idxItem, setIdxItem] = useState(-1);

    const [selectedGnsCode, setSelectedGnsCode] = useState({ value:'', label:'' });

    const [selectedGnsName, setSelectedGnsName] = useState({ value:'', label:'' });

    const [selectedUnit, setSelectedUnit] = useState({ value:'', label:''});

    const [selectedSupplier, setSelectedSupplier] = useState({ value:'', label:'' });

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(constant.DEFAULT_SELECTED);

    const isPrint = true;

    const [purchase, setPurchase] = useState({
        id: 0,
        invoiceNo: `MP${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
        trxDate: DateTime.local(),
        lineNo: 0,
        isCommit: 1,
        supplierId: 0,
        supplierName: "",
        totalPurchase: 0,
        payment: 0,
        outstanding: 0,
        apArId: 0,
        items:[],
        supplierAddress: "",
        note: ""
    });

    const gnsEntity = {
        id: 0,
        code: '',
        name: '',
        purchasePrice: 0,
        sellingPrice: 0,
        qty: 0,
        isSubtrahend: false,
        unitId: 0,
        businessUnitId: 1,
        coaId: 0,
        unit: ''
    }

    const [gns, setGns] = useState(gnsEntity);

    const setGnsState = opt => {

        setGns(gns => ({
            ...gns,
            id: opt.id,
            code: opt.code,
            name: opt.name,
            purchasePrice: opt.purchasePrice,
            sellingPrice: opt.sellingPrice,
            isSubtrahend: opt.isSubtrahend,
            unitId: opt.unitId,
            businessUnitId: opt.buId,
            unit: opt.unit
        }));
    }

    const setSelectedState = opt => {

        setSelectedGnsCode(code => ({
            ...code,
            value: opt.id,
            label: opt.code
        }));

        setSelectedGnsName(name => ({
            ...name,
            value: opt.id,
            label: opt.name
        }));

        setSelectedUnit(unit => ({
            ...unit,
            value: opt.unitId,
            label: opt.unit
        }));

    }

    const resetGns = () => {

        setGns(gnsEntity);

        setSelectedGnsCode(code => ({
            ...code,
            value: '',
            label: ''
        }));

        setSelectedGnsName(name => ({
            ...name,
            value: '',
            label: ''
        }));

        setSelectedUnit(unit => ({
            ...unit,
            value: '',
            label: ''
        }));

    }

    const onGnsCodeChange = opt => {

        if (opt.id) {

            setGnsState(opt);
            
            setSelectedState(opt);

        } else {

            setGns(gns => ({
                ...gns,
                code: opt.label
            }));

            setSelectedGnsCode(code => ({
                ...code,
                value: 0,
                label: opt.label
            }));
        }
    }

    const onGnsNameChange = opt => {

        if (opt.id) {

            setGnsState(opt);

            setSelectedState(opt);

        } else {

            setGns(gns => ({
                ...gns,
                name: opt.label
            }));

            setSelectedGnsName(code => ({
                ...code,
                value: 0,
                label: opt.label
            }));
        }
    }

    const onUnitChange = opt => {

        if (opt.id) {

            setGns(data => ({
                ...data,
                unitId: opt.id,
                unit: opt.code
            }));

            setSelectedUnit(unit => ({
                ...unit,
                value: opt.id,
                label: opt.code
            }));
        }
    }

    const onQtyChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            qty: parseFloat(value)
        }));

    }

    const onPurchasePriceChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            purchasePrice: parseFloat(value),
            sellingPrice: getSellingPrice(parseFloat(value))
        }));

    }

    const onSellingPriceChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            sellingPrice: parseFloat(value)
        }));

    }

    const calculateTotal = items => {

        let total = 0;

        for(let i = 0; i < items.length; i++) {

            const qty = parseFloat(items[i].qty);
            const purchasePrice = parseFloat(items[i].purchasePrice);
            total += qty * purchasePrice;

        }

        return total;

    };

    const onNewItem = e => {

        e.preventDefault();

        resetGns();

        setIdxItem(-1);

    }

    const onAddItem = e => {

        e.preventDefault();

        const addPurchasedItem = () => {

            let gsItems = purchase.items;
            gsItems.push(gns);

            return gsItems;

        }

        const updatePurchasedItem = () => {

            let gsItems = purchase.items;

            for (let i = 0; i < gsItems.length; i++) {

                if (i === idxItem) {

                    gsItems[i].id = gns.id;
                    gsItems[i].code = gns.code;
                    gsItems[i].name = gns.name;
                    gsItems[i].purchasePrice = gns.purchasePrice;
                    gsItems[i].sellingPrice = gns.sellingPrice;
                    gsItems[i].qty = gns.qty;
                    gsItems[i].unitId = gns.unitId;
                    gsItems[i].unit = gns.unit;
                    gsItems[i].businessUnitId = gns.businessUnitId;

                    break;
                } 
            }

            return gsItems;

        }

        let gsItems = [];

        if (idxItem < 0) gsItems = addPurchasedItem();
        else gsItems = updatePurchasedItem();

        const total = calculateTotal(gsItems);

        setPurchase(data => ({
            ...data,
            totalPurchase: total,
            outstanding: total,
            items: gsItems
        }));

        resetGns();

        setIdxItem(-1);

        closeModal(e);
    }

    const onSupplierChange = opt => {

        if (opt.id) {

            setPurchase(data => ({ 
                ...data,
                apArId: opt.apArId,
                supplierId: opt.id,
                supplierName: opt.name,
                supplierAddress: opt.address,
            }));

            setSelectedSupplier(supplier => ({
                ...supplier,
                value: opt.id,
                label: opt.name
            }));

        } 

    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setPurchase(purchase => ({ 
            ...purchase,
            [name]: value
        }));

    };

     const onChangePaymentMethod = async (opt) => {

        if (opt.id) {

            setPurchase(data => ({ 
                ...data,
                paymentMethodId: opt.id,
                paymentMethodName: opt.name
            }));

            setSelectedPaymentMethod(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onDayChange = day => {

        setPurchase(data => ({ 
            ...data,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));

    }

    const openModal = (e) => {

        e.preventDefault();

        setIsOpen(true);

    };

    const closeModal = (e) => {
        
        e.preventDefault();

        setIsOpen(false);

    }

    const onViewDetail = (e, index) => {

        const item = purchase.items[index];

        setGnsState(item);

        setGns(data => ({
            ...data,
            qty: item.qty
        }));

        setIdxItem(index);

        openModal(e);
    }

    const onPaymentChange = values => {

        const { value } = values;

        const gsItems = purchase.items;
        
        const total = calculateTotal(gsItems);

        const outstanding = parseFloat(total) - value;

        setPurchase(purchase => ({
            ...purchase,
            outstanding: outstanding,
            payment: value
        }));

    }

    const onSubmit = (e) => {

        e.preventDefault();

        setRetailBalance(0);

        const post = async () => {

            const result = await postData(purchasePath.base, purchase);

            if (!_.isEmpty(result)) {

                onSuccess(`Pembelian ${purchase.invoiceNo} berhasil di tambahkan`);

                history.goBack();
            }
        };

        if (purchase.totalPurchase) post();
        
    }

    const getPurchaseDetail = async (id) => {

        const result = await getData(`${purchasePath.base}/${id}`);

        return result;
    }


    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
    
                setPurchase(state);

                setIsNew(false);
                
                setTitleInfo(`Detail: ${state.invoiceNo}`);

                setSelectedSupplier(supplier => ({
                    ...supplier,
                    value: state.supplierId,
                    label: state.supplierName
                }));

                setSelectedPaymentMethod(name => ({
                    ...name,
                    value: state.paymentMethodId,
                    label: state.paymentMethodName
                }));

                getPurchaseDetail(state.id)
                    .then(result => {

                        setPurchase(data => ({
                            ...data,
                            items: result
                        }));

                    });
    
            } else {
    
                setTitleInfo("Tambah Pembelian Baru");
                
            }
        }

        init();

    }, []);

    useEffect(() => {

        if (purchase.invoiceNo && purchase.supplierId && purchase.supplierName && purchase.trxDate) setIsDisabledAdd(false);

        if (purchase.totalPurchase) setIsDisabledSave(false);

    }, [purchase.invoiceNo, purchase.supplierId, purchase.supplierName, purchase.trxDate, purchase.totalPurchase]);

    return(
        <React.Fragment>
            { isPrint &&
            <div>
                <ComponentToPrint 
                    invoiceNo = {purchase.invoiceNo}
                    trxDate={`${formatDate(purchase.trxDate, 'LL', constant.LOCALE)}`}
                    supplierName={purchase.supplierName}
                    totalPurchase = {purchase.totalPurchase}
                    payment = {purchase.payment}
                    outstanding = {purchase.outstanding}
                    supplierAddress = {purchase.supplierAddress}
                    supplierPhoneNo = {purchase.supplierPhoneNo}
                    note = {purchase.note}
                    items = {purchase.items}
                    
                    ref={componentRef} />

                    <button onClick={handlePrint}>Print this out!</button>
            </div>
            }

            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ purchasePath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <Modal
                        open={ modalIsOpen }
                        onClose={ closeModal }>
                            <div className="modal-header">
                                <h4 id="purchase-title" className="modal-title">Tambah Detail Pembelian</h4>
                            </div>
                            <div className="modal-body">
                                <form>

                                    <div className="form-group">
                                        <Label htmlFor="gns-name" text="Nama Barang" isMandatory={ true } value={ gns.name } />
                                        <SelectCreate
                                            id="gns-name"
                                            api={ purchasePath.gnsFind }
                                            entityId="1"
                                            value={ selectedGnsName }
                                            onChange={ onGnsNameChange }
                                        />
                                    </div>

                                    <div className="form-group">
                                        <Label htmlFor="gns-code" text="Kode Barang" isMandatory={ true } value={ gns.code } />
                                        <SelectCreate 
                                            id="gns-code"
                                            api={ purchasePath.gnsFind }
                                            value={ selectedGnsCode }
                                            onChange={ onGnsCodeChange }
                                        />
                                    </div>

                                    <div className="form-group">
                                        <Label htmlFor="unit" text="Satuan" isMandatory={ true } value={ gns.unit } />
                                        <Select 
                                            id="unit"
                                            api={ purchasePath.unitFilter }
                                            value={ selectedUnit }
                                            onChange={ onUnitChange }
                                        />
                                        <span><LinkButton to={ purchasePath.newUnit } className="stretched-link" label="Tambah Satuan Baru" /></span>
                                    </div>

                                    <div className="form-group">
                                        <Label htmlFor="qty" text="Quantity" isMandatory={ true } value={ gns.qty } />
                                        <InputNumeral
                                            id="qty"
                                            value={ gns.qty }
                                            onValueChange={ onQtyChange }
                                        />
                                    </div>

                                    <div className="form-group">
                                        <Label htmlFor="purchase-price" text="Harga Beli" isMandatory={ true } value={ gns.purchasePrice } />
                                        <InputNumeral
                                            id="purchase-price"
                                            value={ gns.purchasePrice }
                                            onValueChange={ onPurchasePriceChange }
                                        />
                                    </div>

                                    <div className="form-group">
                                        <Label htmlFor="selling-price" text="Harga Jual" isMandatory={ true } value={ gns.sellingPrice } />
                                        <InputNumeral
                                            id="selling-price"
                                            value={ gns.sellingPrice }
                                            onValueChange={ onSellingPriceChange }
                                        />
                                    </div>

                                    <div className="form-group">
                                        <Note isAction={ true } hideHeader={ true } />
                                    </div>
                                </form>
                            </div>

                            <div className="modal-footer">
                                {
                                    gns.code &&
                                    <>
                                        <ClickButton onClick={ onNewItem } className="btn btn-info" />&nbsp;
                                    </>
                                    
                                }
                                <ClickButton onClick={ onAddItem } className="btn btn-success" label={ constant.SAVE } />
                            </div>
                    </Modal>
                </div>
                
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="inv-no" text="No Faktur" isMandatory={ true } value={ purchase.invoiceNo } />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        value={ purchase.invoiceNo }
                                        disabled />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="supplier" text="Supplier" isMandatory={ true } value={ purchase.supplierName } />
                                    <Select 
                                        id="supplier"
                                        paramFilter="name"
                                        api={ purchasePath.custFind }
                                        entityId="4"
                                        value={ selectedSupplier }
                                        onChange={ onSupplierChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="note" text="Catatan" />
                                    <div>
                                        <textarea 
                                            id="note"
                                            className="form-control" 
                                            name="note"
                                            value={purchase.note}
                                            onChange={ e => onInputChange(e) } 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="address" text="Alamat" />
                                    <div>
                                        <textarea 
                                            id="address"
                                            className="form-control" 
                                            name="address"
                                            value={purchase.supplierAddress}
                                            onChange={ e => onInputChange(e) } 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="trx-date" text="Tanggal" isMandatory={ true } value={ purchase.trxDate } />
                                    {
                                        tlock === '1' && <input disabled type='text' className='form-control' value={`${formatDate(purchase.trxDate, 'LL', constant.LOCALE)}`} />
                                    }
                                    {
                                        tlock === '0' && <DayPicker id="trx-date" name="trxDate" valueDate={ purchase.trxDate } onDayChange={ onDayChange } />
                                    }
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-6">
                        <form onSubmit={ e => onSubmit(e) }>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="total" text="Total" />
                                    <InputNumeral 
                                        id="total"
                                        value={ purchase.totalPurchase }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            {
                                isNew &&
                                <>
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <Label htmlFor="payment" text="Jumlah Bayar" />
                                            <InputNumeral 
                                                id="payment"
                                                value={ purchase.payment }
                                                onValueChange={ onPaymentChange }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <Label htmlFor="outstanding" text="Sisa Pembayaran" />
                                            <InputNumeral 
                                                id="outstanding"
                                                value={ purchase.outstanding }
                                                display={ true }
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <Label htmlFor="payment-method" text="Metode Pembayaran" isMandatory={ true } value={ purchase.paymentMethodName} />
                                            <Select
                                                id="payment-method"
                                                paramFilter="name"
                                                api={ purchasePath.paymentMethodFind }
                                                onChange={ onChangePaymentMethod }
                                                value={ selectedPaymentMethod }
                                            />
                                        </div>
                                    </div>
                                </>  
                            }

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label />
                                    <ActionButton
                                        isSave={ isNew }
                                        isCancel={ false }
                                        isPrint={ isPrint }
                                        onPrint={handlePrint}
                                        isDisabledSave={ isDisabledSave }
                                        isDisabledAdd={ isDisabledAdd }
                                        isModal={ true }
                                        openModal={ openModal }
                                        addLabel="Tambah Detail Pembelian" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <br />
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6"><Note isAction={ true } /></div>
                </div>

                <br />

                {
                    purchase.items && 

                    <div className="col-lg-12 mb-4">
                        <div className="row">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Kode Barang</th>
                                            <th>Nama Barang</th>
                                            <th>Satuan</th>
                                            <th>Quantity</th>
                                            <th>Harga Beli</th>
                                            <th>Sub Total</th>
                                            {
                                                isNew &&
                                                <th>Detail</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            purchase.items.map((item, index) => {
                                                return(
                                                    <tr key={ index }>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ item.code }</td>
                                                        <td>{ item.name }</td>
                                                        <td>{ item.unit }</td>
                                                        <td>{ item.qty }</td>
                                                        <td><TextNumber value={ item.purchasePrice } /></td>
                                                        <td><TextNumber value={ item.qty * item.purchasePrice } /></td>
                                                        {
                                                            isNew &&
                                                            <td>
                                                                <button className={`btn ${constant.BTN_CLASS_DETAIL}`} onClick={ (e) => onViewDetail(e, index) }>Detail</button>
                                                            </td>
                                                        }
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>
    );
};

export default Action;
