import React from "react";

const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      props.items &&
        <div ref={ref}>

        <table className="bill">
            <tr>
                <td style={{width:"600px", paddingRight:"10px"}}>PERMATA MATERIAL BANGUNAN PEKANBARU, <br/>
                    JALAN PEMUDA NO 123, PEKANBARU<br/>
                    Admin 1: 082285808188<br/>
                    Admin 2: 081371660956
                </td>
                <td style={{width:"400px"}}>
                    <u>PURCHASE ORDER</u><br/>
                    No. {props.invoiceNo}
                </td>
            </tr>
        </table>
        <br/>
        <table className="bill">
            <tr>
                <td style={{width:"600px"}}>
                    <table>
                        <tr>
                        <td>Diantar Ke,</td>
                        </tr>
                        <tr>
                        <td>Tanggal</td>
                        <td>:</td>
                        <td>{props.trxDate}</td>
                        </tr>
                        <tr>
                        <td>Nama</td>
                        <td>:</td>
                        <td>{props.custName}</td>
                        </tr>
                        <tr>
                        <td>No Hp</td>
                        <td>:</td>
                        <td>{props.customerPhoneNo}</td>
                        </tr>
                        <tr>
                        <td>Alamat</td>
                        <td>:</td>
                        <td>{props.customerAddress}</td>
                        </tr>
                    </table>
                </td>
                <td style={{width:"400px"}}>
                    <table>
                        <tr>
                        <td>Order Ke,</td>
                        </tr>
                        <tr>
                        <td>Tanggal</td>
                        <td>:</td>
                        <td>{props.trxDate}</td>
                        </tr>
                        <tr>
                        <td>Nama</td>
                        <td>:</td>
                        <td>{props.supplierName}</td>
                        </tr>
                        <tr>
                        <td>No Hp</td>
                        <td>:</td>
                        <td>{props.supplierPhoneNo}</td>
                        </tr>
                        <tr>
                        <td>Alamat</td>
                        <td>:</td>
                        <td>{props.supplierAddress}</td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>

        <br/>

        { props.rePrint === 1 ?
        <table className="bord">
          <thead>
              <tr>
                  <td style={{width:"200px"}}>QTY</td>
                  <td style={{width:"800px"}}>NAMA BARANG</td>
              </tr>
          </thead>
            {
                props.items.map((item, index) => {
                    return(
                        <tbody>
                            <tr key={ index }>
                                <td className="quantity">{ item.qty +" "+ item.unit }</td>
                                <td className="description">{ item.name }</td>
                            </tr>
                        </tbody>
                    );
                })
            }
        </table>
        :
        <table className="bord">
            <thead>
                <tr>
                    <td style={{width:"100px"}}>QTY</td>
                    <td style={{width:"500px"}}>NAMA BARANG</td>
                    <td style={{width:"200px"}}>@ HARGA</td>
                    <td style={{width:"200px"}}>JUMLAH</td>
                </tr>
            </thead>
              {
                  props.items.map((item, index) => {
                      return(
                          <tbody>
                              <tr key={ index }>
                                  <td className="quantity">{ item.qty +" "+ item.unit }</td>
                                  <td className="description">{ item.name }</td>
                                  <td className="price"><p class="alignright">{item.purchasePrice.toLocaleString()}</p></td>
                                  <td className="total"><p class="alignright">{(item.qty * item.purchasePrice).toLocaleString()}</p></td>
                              </tr>
                          </tbody>
                      );
                  })
              }
              <tbody>
                <tr>
                  <td colspan="3">Total</td>
                  <td>{props.totalPurchase.toLocaleString()}</td>
                </tr>
            </tbody>
        </table>
        }

        <br/>
        <br/>
        
        <table>
          <tr>
            <td style={{width:"500px"}}>Note:</td>
            <td style={{width:"250px"}}>Dikirim Oleh,</td>
            <td style={{width:"250px"}}>Diterima Oleh,</td>
          </tr>
          <tr>
            <td>{ props.note }</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>Tanggal:</td>
            <td>Tanggal:</td>
          </tr>
        </table>
            
        </div>
      
    );
})

export default ComponentToPrint;