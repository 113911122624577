import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Note from '../../../components/note';
import Label from '../../../components/label';
import Select from '../../../components/select';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import ClickButton from '../../../components/click-button';
import ActionButton from '../../../components/action-button';
import { getData, postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import lossesPath from './config';

const Action = () => {

    const data = useLocation();
    const refData = useRef(data);
    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isDisabledAdd, setIsDisabledAdd] = useState(true);

    const [isSave, setIsSave] = useState(true);

    const [idxItem, setIdxItem] = useState(-1);

    const [selectedGnsName, setSelectedGnsName] = useState({ value: '', label: ''});

    const [losses, setLosses] = useState({
        id: 0,
        invoiceNo: `TL${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
        trxDate: DateTime.local(),
        lineNo: 0,
        isCommit: 1,
        totalLosses: 0,
        items:[]
    });

    const gnsEntity = {

        id: 0,
        code: '',
        name: '',
        purchasePrice: 0,
        sellingPrice: 0,
        qty: 0,
        stock: 0,
        isSubtrahend: false,
        unitId: 0,
        businessUnitId: 2,
        coaId: 0,
        unit: '',
        description: ''
    }

    const [gns, setGns] = useState(gnsEntity);

    const resetGns = () => {

        setGns(gnsEntity);

        setSelectedGnsName(name => ({
            ...name,
            value: '',
            label: ''
        }));
    }

    const onGnsNameChange = opt => {

        if (opt.id) {

            setGns(gns => ({
                ...gns,
                id: opt.id,
                code: opt.code,
                name: opt.name,
                purchasePrice: opt.purchasePrice,
                sellingPrice: opt.sellingPrice,
                stock: opt.stock,
                isSubtrahend: opt.isSubtrahend,
                unitId: opt.unitId,
                businessUnitId: opt.buId,
                coaId: opt.coaId,
                unit: opt.unit
            }));

            setSelectedGnsName(name => ({
                ...gns,
                value: opt.id,
                label: opt.name
            }));

        } 

    }

    const calculateTotal = items => {

        let total = 0;

        for(let i = 0; i < items.length; i++) {

            const qty = parseInt(items[i].qty);
            const purchasePrice = parseInt(items[i].purchasePrice);
            total += qty * purchasePrice;

        }

        return total;

    };

    const onAddItem = e => {

        e.preventDefault();

        const addLossItem = () => {

            let gsItems = losses.items;
            gsItems.push(gns);

            return gsItems;

        }

        const updateLossItem = () => {

            let gsItems = losses.items;

            for (let i = 0; i < gsItems.length; i++) {

                if (i === idxItem) {

                    gsItems[i].id = gns.id;
                    gsItems[i].code = gns.code;
                    gsItems[i].name = gns.name;
                    gsItems[i].purchasePrice = gns.purchasePrice;
                    gsItems[i].qty = gns.qty;
                    gsItems[i].description = gns.description;

                    break;
                } 
            }

            return gsItems;

        }


        let gsItems = [];

        if (idxItem < 0) gsItems = addLossItem();
        else gsItems = updateLossItem();

        const total = calculateTotal(gsItems);

        setLosses(data => ({
            ...data,
            totalLosses: total,
            outstanding: total,
            items: gsItems
        }));

        resetGns();

        setIdxItem(-1);

        setIsDisabledSave(false);
    }

    const onPurchasePriceChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            purchasePrice: parseFloat(value)
        }));
    }

    const onQtyChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            qty: parseFloat(value)
        }));
    }

    const onDescChange = e => {

        const { value } = e.target;

        setGns(gns => ({
            ...gns,
            description: value
        }));
    }

    const onDayChange = day => {

        setLosses(data => ({ 
            ...data,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));

        if (losses.customerName) setIsDisabledAdd(false);

    }

    const onViewDetail = (index) => {

        const item = losses.items[index];

        setGns(data => ({
            ...data,
            id: item.id,
            code: item.code,
            name: item.name,
            unit: item.unit,
            unitId: item.unitId,
            stock: item.stock,
            qty: item.qty,
            purchasePrice: item.purchasePrice,
            description: item.description
        }));

        setSelectedGnsName(name => ({
            ...name,
            value: item.id,
            label: item.name
        }));

        setIdxItem(index);
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const post = async () => {

            const result = await postData(lossesPath.base, losses);

            if (Object.keys(result).length) {

                onSuccess(`Kerugian ${losses.invoiceNo} berhasil di tambahkan`);

                history.goBack();

            }
        };

        if (losses.id === 0 && losses.totalLosses) post();
        
    }

    const getLossesDetail = async (id) => {

        const result = await getData(`${lossesPath.base}/${id}`);

        return result;
    }


    useEffect(() => {

        function init() {

            const state = refData.current.state;

            if (state) {
    
                setLosses(state);

                setIsSave(false);
                
                setTitleInfo(`Detail: ${state.invoiceNo}`);

                getLossesDetail(state.id)
                    .then(result => {

                        setLosses(data => ({
                            ...data,
                            items: result
                        }));

                    });
    
            } else {
    
                setTitleInfo("Tambah Kerugian Baru");
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (losses.totalLosses) setIsDisabledSave(false);

        if (gns.code && gns.name && gns.purchasePrice && gns.qty && gns.description) setIsDisabledAdd(false);
        else setIsDisabledAdd(true);

    }, [losses.totalLosses, gns.code, gns.name, gns.purchasePrice, gns.qty, gns.description]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ lossesPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-6 border-right">
                        <form>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="inv-no" text="No Faktur" isMandatory={ true } value={ losses.invoiceNo } />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        value={ losses.invoiceNo }
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="trx-date" text="Tanggal" isMandatory={ true } value={ losses.trxDate } />
                                    <div>
                                        <DayPicker id="trx-date" name="trxDate" valueDate={ losses.trxDate } onDayChange={onDayChange} />
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-6">
                        <form onSubmit={ e => onSubmit(e) }>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="total" text="Total" isMandatory={ true } value={ losses.totalLosses } />
                                    <InputNumeral 
                                        id="total"
                                        value={ losses.totalLosses }
                                        display= { true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label />
                                    <ActionButton 
                                        isSave={ isSave }
                                        isCancel={ false }
                                        isDisabledSave={ isDisabledSave }
                                        isDisabledAdd={ isDisabledAdd }
                                        addLabel="Tambah Detail Pembelian" />
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                {
                    isSave &&
                    <>
                        <div className="line"></div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Label htmlFor="gns-name" text="Nama Barang" isMandatory={ true } value={ gns.name } />
                                        <Select
                                            id="gns-name"
                                            api={ lossesPath.gnsFind }
                                            entityId="2"
                                            value={ selectedGnsName }
                                            onChange={ onGnsNameChange }
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <Label htmlFor="purchase-price" text="Harge Beli" isMandatory={ true } value={ gns.purchasePrice } />
                                        <InputNumeral 
                                            id="purchase-price"
                                            value={ gns.purchasePrice }
                                            onValueChange={ onPurchasePriceChange }
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <Label htmlFor="qty" text="Qty Kerugian" isMandatory={ true } value={ gns.qty } />
                                        <InputNumeral 
                                            id="qty"
                                            value={ gns.qty }
                                            onValueChange={ onQtyChange }
                                        />
                                    </div>
                                    
                                </div>

                                <div className="form-group row">

                                    <div className="col-md-4">
                                        <Label text="Satuan" />
                                        <input type="text" className="form-control" value={ gns.unit } disabled />
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <Label text="Stock" />
                                        <InputNumeral 
                                            value={ gns.stock }
                                            display={ true }
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <Label htmlFor="description" text="Deskripsi Kerugian" isMandatory={ true }/>
                                        <textarea 
                                            id="description"
                                            className="form-control" 
                                            name="description"
                                            value={ gns.description }
                                            onChange={ e => onDescChange(e) } 
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <br />
                                        <br />
                                        <Note isAction={ true } />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="btn-group d-flex">
                                            <ClickButton isDisabled={ isDisabledAdd } className="p-2 flex-fill btn btn-success" label={ idxItem === -1 ? `Tambah` : `Perbaharui` } onClick={ onAddItem } />
                                            {
                                                gns.code &&
                                                <ClickButton onClick={ resetGns } className="p-2 flex-fill btn btn-info" />
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                <br />

                {
                    losses.items && 
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nama Barang</th>
                                            <th>Deskripsi</th>
                                            <th>Satuan</th>
                                            <th>Quantity</th>
                                            <th>Harga Beli</th>
                                            <th>Sub Total</th>
                                            {
                                                isSave &&
                                                <th>Opsi</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            losses.items.map((item, index) => {
                                                return(
                                                    <tr key={ index }>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ item.name }</td>
                                                        <td>{ item.description }</td>
                                                        <td>{ item.unit }</td>
                                                        <td><TextNumber value={ item.qty } /></td>
                                                        <td><TextNumber value={ item.purchasePrice } /></td>
                                                        <td><TextNumber value={ parseFloat(item.qty) * parseFloat(item.purchasePrice) } /></td>
                                                        {
                                                            isSave &&
                                                            <td>
                                                                <button className={`btn ${constant.BTN_CLASS_DETAIL}`} onClick={ () => onViewDetail(index) }>Detail</button>
                                                            </td>
                                                        }
                                                        
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>
    );
};

export default Action;
