import React, { useState, useEffect } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import { useGlobalState } from 'state-pool';

import Label from '../../../components/label';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import ReportButton from '../../../components/report-button';
import DayPicker from '../../../components/day-picker';
import Select from '../../../components/select';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import stockSalesPath from './config';
import LoaderType from '../../../components/loader-type';

const List = () => {

    const stockSalesSummaryEntity = {
        initBalance: 0,
        lastBalance: 0,
        purchase: 0,
        sales: 0,
        loss: 0,
        profit: 0
    }

    const [isLoad, setIsLoad] = useState(true);

    const [materialBuId, ,] = useGlobalState('materialBuId');

    const [endDate, setEndDate] = useState(DateTime.local());

    const [reset, setReset] = useState();

    const [selectedGns, setSelectedGns] = useState(constant.DEFAULT_SELECTED);

    const [gnsId, setGnsId] = useState();

    const [stockSales, setStockSales] = useState([]);

    const [stockSalesSummary, setStockSalesSummary] = useState(stockSalesSummaryEntity);

    const onEndDateChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));;

    const getStockSales = () => {

        const params = getParams();

        setIsLoad(true);

        getData(stockSalesPath.base, params).then(result => setStockSales(result[0]));

        getData(stockSalesPath.stockSalesSum, params).then(result => {

            if (Object.keys(result[0]).length) {

                setStockSalesSummary(sum => ({
                    ...sum,
                    initBalance: result[0][0].initBalance,
                    lastBalance: result[0][0].lastBalance,
                    purchase: result[0][0].purchase,
                    sales: result[0][0].sales,
                    loss: result[0][0].loss,
                    profit: result[0][0].profit
                }));

            }

            setIsLoad(false);

        });

    }

    const getParams = () => {

        const firstDate = DateTime.fromISO(endDate).startOf('month');

        const params = {
            params: { 
                'start-date': DateTime.fromISO(firstDate).toFormat(constant.PARAM_DATE_FORMAT),
                'end-date': DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                'gns-id': gnsId,
                'bu-id': materialBuId
            }
        };

        return params;
    }

    const onNameChange = opt => {

        if (opt.id) {
            
            setSelectedGns(name => ({
                ...name,
                value: opt.id,
                label: opt.label
            }));

            setGnsId(opt.id);

        }

    }
    
    const onExport = e => {

        e.preventDefault();

        const params = getParams();

        getFile(stockSalesPath.stockSalesExcel, params).then(response => downloadFile(response));

    }

    const onSubmit = e => {

        e.preventDefault();

        getStockSales();

    }

    const onReset = e => {

        e.preventDefault();

        setIsLoad(true);

        setEndDate(DateTime.local());

        setSelectedGns(constant.DEFAULT_SELECTED);

        setStockSalesSummary(stockSalesSummaryEntity);

        setGnsId();

        setReset('reset');
    }

    useEffect(getStockSales, [reset]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ stockSalesPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="end-date" text="Tanggal" />
                                    <DayPicker id="end-date" name="end-date" onDayChange={ onEndDateChange } valueDate={ endDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                
                                <div className="col-md-12">
                                    <Label htmlFor="gns-name" text="Nama Barang" />
                                    <Select
                                        id="gns-name"
                                        api={ stockSalesPath.stockSalesFind }
                                        entityId={ materialBuId }
                                        onChange={ onNameChange }
                                        value={ selectedGns }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    {
                        !isLoad && 
                        <div className="col-md-6">
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <h3>Rekapitulasi</h3>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label text="Saldo Awal (Rp)" />
                                    <InputNumeral
                                        value={ stockSalesSummary.initBalance }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label text="Saldo Akhir (Rp)" />
                                    <InputNumeral
                                        value={ stockSalesSummary.lastBalance }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label text="Jumlah Pembelian (Rp)" />
                                    <InputNumeral
                                        value={ stockSalesSummary.purchase }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label text="Jumlah Penjualan (Rp)" />
                                    <InputNumeral
                                        value={ stockSalesSummary.sales }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label text="Rugi (Rp)" />
                                    <InputNumeral
                                        value={ stockSalesSummary.loss }
                                        display={ true }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Label text="Laba (Rp)" />
                                    <InputNumeral
                                        value={ stockSalesSummary.profit }
                                        display={ true }
                                    />
                                </div>
                            </div>
                        </div>
                    }

                </div>

                {
                    isLoad &&
                    <LoaderType />
                }
                
                {
                    !isLoad && stockSales.length > 0 &&
                    <>
                        <br />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nama Barang</th>
                                                <th>Stok Awal</th>
                                                <th>Pembelian (+)</th>
                                                <th>Harga Beli</th>
                                                <th>Penjualan (-)</th>
                                                <th>Harga Jual</th>
                                                <th>Kerugian (-)</th>
                                                <th>Stok Akhir</th>
                                                <th>Stok Saat Ini</th>
                                                <th>Saldo Akhir (Rp)</th>
                                                <th>Laba (%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                stockSales.map((ss, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <td>{ index + 1 }</td>
                                                            <td>{ ss.gnsName }</td>
                                                            <td><TextNumber value={ ss.firstStock } /></td>
                                                            <td><TextNumber value={ ss.purchaseQty } /></td>
                                                            <td><TextNumber value={ ss.purchasePrice } /></td>
                                                            <td><TextNumber value={ ss.salesQty } /></td>
                                                            <td><TextNumber value={ ss.sellingPrice } /></td>
                                                            <td><TextNumber value={ ss.lossQty } /></td>
                                                            <td><TextNumber value={ ss.lastStock } /></td>
                                                            <td><TextNumber value={ ss.currentStock } /></td>
                                                            <td><TextNumber value={ ss.balance } /></td>
                                                            <td><TextNumber value={ ss.profitPercent } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
