import React, { useState } from 'react';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import { postData, getData } from '../helper/request-response';
import apiPath from '../config/api';


const Login = props => {

    const [, , setToken] = useGlobalState('token');
    const [, , setUser] = useGlobalState('user');
    const [, , setIsAuthenticated] = useGlobalState('isAuthenticated');
    const [, , setMaterialBuId] = useGlobalState('materialBuId');
    const [, , setTechniqueBuId] = useGlobalState('techniqueBuId');
    const [, , setTlock] = useGlobalState('tlock');
    const [appTitle, ,] = useGlobalState('appTitle');
    const [appEntity, ,] = useGlobalState('appEntity');
    const [appPos, ,] = useGlobalState('appPos');
    const [loginImage, ,] = useGlobalState('loginImage');

    const [login, setLogin] = useState({ username: '', password: '' });

    const onInputChange = e => {

        e.persist();

        setLogin(lgn => ({
            ...lgn,
            [e.target.name]: e.target.value
        }));

    }

    const onClick = e => {
        
        e.preventDefault();
        
        postData(apiPath.adminLogin, { 'username': login.username, 'password': login.password }).then(result => {

            if (!_.isEmpty(result)) {

                setToken(_.get(result, 'token'));
                setUser(_.get(result, 'user'));
                setIsAuthenticated(true);

                getData(apiPath.masterBuCodeFilter, {'params': { 'keyword': 'A01' }}).then(result => {

                    setMaterialBuId(result[0].id);

                });

                getData(apiPath.masterBuCodeFilter, {'params': { 'keyword': 'A02' }}).then(result => {

                    setTechniqueBuId(result[0].id);

                });

                getData(apiPath.transactionLock).then(result => {

                    setTlock(result[0].val);

                });

                props.history.push("/dashboard");

            } else {

                setToken('');
                setUser({});
                setIsAuthenticated(false);
                setTlock('');

                setLogin(usr => ({
                    ...usr,
                    username: '',
                    password: ''
                }));

            }

        });
    }

    return(
        <div className="page-holder d-flex align-items-center">
            <div className="container py-5">
                <div className="row align-items-center">
                    <div className="col-5 col-lg-7 mx-auto mb-5 mb-lg-0">
                        <div className="pr-lg-5"><img src={`${loginImage}`} alt="" className="img-fluid" /></div>
                    </div>

                    <div className="col-lg-5 px-lg-4">
                        <h1 className="text-base text-primary text-uppercase mb-4">{ appTitle } { appPos && <span>POS</span> }</h1>
                        <h2 className="mb-4">Selamat Datang Kembali!</h2>
                        <p className="text-muted">Sistem Pencatatan { appPos && <span>Transaksi Pembelian dan Penjualan</span> } { appEntity }</p>
                        <form id="loginForm" className="mt-4">
                            <div className="form-group mb-4">
                                <input 
                                    type="text"
                                    name="username"
                                    placeholder="Nama Pengguna"
                                    aria-label="Username"
                                    className="form-control border-0 shadow form-control-lg" 
                                    style={{backgroundImage: "url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;)", backgroundRepeat: "no-repeat", backgroundAttachment: "scroll", backgroundSize: "16px 18px", backgroundPosition: "98% 50%" }} 
                                    autoComplete="off"
                                    onChange={ e => onInputChange(e) } 
                                    value={ login.username } />
                            </div>
                            <div className="form-group mb-4">
                                <input 
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    aria-label="Password"
                                    className="form-control border-0 shadow form-control-lg text-violet" 
                                    style={{backgroundImage: "url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;)", backgroundRepeat: "no-repeat", backgroundAttachment: "scroll", backgroundSize: "16px 18px", backgroundPosition: "98% 50%" }} 
                                    autoComplete="off"
                                    onChange={ e => onInputChange(e) } 
                                    value={ login.password } />
                            </div>
                    
                            <button onClick={ onClick } type="submit" className="btn btn-primary shadow px-5">Log in</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
